<template>
    <div class="floor-style-1 floor-wsw" :id="data.block_name + c_index">
        <div class="head-wrap" v-if="data.value.title.value.text">
            <h2 @click="$router.pushToTab(data.value.title.value.link.url)"
                :style="{ color: data.value.title.value.color }">{{ data.value.title.value.text }}</h2>
        </div>
        <div class="body-wrap">
            <div class="left-wrap" v-if="data.value.leftImg.value.url">
                <img :src="$img(data.value.leftImg.value.url)"
                    @click="$router.pushToTab(data.value.leftImg.value.link.url)" />
            </div>
            <ul class="goods-list">
                <li class="hoverBox hoverBoder" v-for="(item, index) in data.value.goodsList.value.list" :key="index" :title="item.goods_name"
                    @click="goSku(item)">
                    <div class="img-wrap">
                        <img alt="商品图片" :src="$img(item.goods_image.split(',')[0], { size: 'mid' })"
                            @error="imageError(index)" />
                    </div>
                    <h3 class="goodsName">{{ item.goods_name }}</h3>
                    <p class="desc">{{ item.introduction }}</p>
                    <ctrlShowText :info="item" type="custom" margin="0px 30px 10px 30px" size="12px"  gap="20px" color="#b0b0b0"
                    :wesArr="['规格','厂家']" :traArr="['规格','厂家']"></ctrlShowText>
                    <!-- <p class="desc">效期：{{ item.attr.attr_validity }}</p> -->
                    <template v-if="$store.getters.token">
                        <p class="price" v-if="item.sale_control && item?.sale_control.view_price">
                            <span class="num">¥{{ item.discount_price }}元</span>
                            <del v-if="item.market_price > 0">¥{{ item.market_price }}元</del>
                        </p>
                        <p class="price" v-else>
                            <span class="num-conter">¥{{ item.sale_control.not_buy_msg }}</span>
                            <!-- <del>¥暂无权限！</del> -->
                        </p>
                    </template>
                    <template v-else>
                        <p class="price">
                            <span class="num-conter">¥登录可见</span>
                            <!-- <del>¥登录可见</del> -->
                        </p>
                    </template>
                    <div class="cart-layout">
                        <div>
                            <shareCounter :number="item.min_buy" :goodsSkuDetail="{
                                min_buy: item.min_buy,
                                stock: item.goods_stock,
                                package_num: item.package_num || item.min_buy,
                                is_member_by: item.sale_control?.is_member_by,
                                view_price: item.sale_control?.view_price,
                                sale_control_name: item.sale_control?.sale_control_name
                            }" size="mini" @upnumber="upnumber"></shareCounter>
                        </div>
                        <template v-if="$store.getters.token">
                            <div class="cart-add"
                        :class="item.sale_control && item.sale_control.view_price  ? 'cart-add' : 'ban'"
                        @click.stop="joinCart(item)">加入购物车</div>
                        </template>
                        <template v-else>
                            <div class="cart-add"
                        @click.stop="joinCart(item)">加入购物车</div>
                        </template>
                    </div>
                </li>
            </ul>
        </div>
        <div class="bottom-wrap" v-if="data.value.bottomImg.value.url">
            <img :src="$img(data.value.bottomImg.value.url)"
                @click="$router.pushToTab(data.value.bottomImg.value.link.url)" />
        </div>
    </div>
</template>

<script>
import { onTip } from "@/utils/tips"
import shareCounter from "@/components/shareCounter.vue"
import ctrlShowText from "@/components/ctrl-show-text/ctrl-show-text.vue";
import { mapGetters } from "vuex"
export default {
    name: "floor-style-1",
    components: {
        shareCounter,
        ctrlShowText
    },
    props: {
        data: {
            type: Object
        },
        c_index: {
            type: Number
        }
    },
    data() {
        return {
            number: 1
        }
    },
    created() { },
    computed: {
        ...mapGetters(["defaultGoodsImage", "cartCount", "siteInfo", "member"]),
        logined: function () {
            return this.member !== undefined && this.member !== "" && this.member != {}
        }
    },
    methods: {
        upnumber(val) {
            this.number = val
        },
        goSku(item) {
            if (!this.$store.getters.token) {
                this.$router.pushToTab("/login")
                return
            }
            if (onTip(item.sale_control?.view_price, item.sale_control?.is_member_by, this, item.sale_control?.not_buy_msg)) return
            this.$router.pushToTab("/sku-" + item.sku_id)
        },
        // 加入购物车
        joinCart(item) {
            if (onTip(item.sale_control.view_price, item.sale_control.is_member_by, this, item.sale_control.not_buy_msg)) return

            this.$store
                .dispatch("cart/add_to_cart", {
                    site_id: item.site_id,
                    sku_id: item.sku_id,
                    num: this.number
                })
                .then(res => {
                    if (res.code === 0) {
                        this.$message({
                            message: "加入购物车成功",
                            type: "success"
                        })
                    }
                })
                .catch(err => {
                    if (err.message === "token不存在") {
                        this.$router.pushToTab("/login")
                    } else {
                        this.$message.error(err.message)
                    }
                })
        },
        imageError(index) {
            this.data.value.goodsList.value.list[index].goods_image = this.defaultGoodsImage
        }
    }
}
</script>

<style lang="scss">
.floor-style-1 {
    .head-wrap h2 {
        line-height: 30px;
        color: #333;
        padding: 10px;
        font-size: 18px;
        cursor: pointer;
        width: 95%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .body-wrap {
        .left-wrap {
            float: left;
            width: 234px;
            cursor: pointer;
            transition: all 0.2s linear;

            &:hover {
                z-index: 2;
                -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                -webkit-transform: translate3d(0, -2px, 0);
                transform: translate3d(0, -2px, 0);
            }

            img {
                max-width: 100%;
                cursor: pointer;
            }
        }

        .goods-list {
            margin-left: 235px;
            display: flex;
            flex-wrap: wrap;

            .cart-layout {
                padding: 0 19px;
                display: flex;
                justify-content: center;
                align-items: center;

                .el-input-number--mini {
                    width: 85px !important;
                }

                .cart-num {
                    margin-right: 10px;
                    position: relative;
                    width: 30%;
                    border-radius: 4px;
                    display: flex;
                    border: 1px solid #ccc;
                    justify-content: center;
                    align-items: center;

                    span {
                        z-index: 1;
                        position: absolute;
                        cursor: pointer;
                        width: 20px;
                        text-align: center;
                    }

                    span:first-child {
                        left: 0;
                    }

                    span:last-child {
                        right: 0;
                    }

                    .number {
                        .el-input {
                            .el-input__inner {
                                text-align: center;
                            }
                        }
                    }
                }

                .cart-add {
                    font-size: 12px;
                    margin-left: 5px;
                    cursor: pointer;
                    background: $base-color;
                    color: $base-color-whtie;
                    border-radius: 15px;
                    padding: 2px 10px;
                }
                .ban{
                    font-size: 12px;
                    margin-left: 5px;
                    cursor: not-allowed;
                    background:#dddddd6e;
                    color: $base-color-whtie;
                    border-radius: 15px;
                    padding: 2px 10px;
                }
            }

            li {
                width: 23%;
                margin-left: 19px;
                margin-bottom: 20px;
                background: #fff;
                cursor: pointer;
                padding: 10px 0;
                transition: all 0.2s linear;
                border: 1px solid #dddddd6e;

                &:hover {
                    z-index: 2;
                    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                    -webkit-transform: translate3d(0, -2px, 0);
                    transform: translate3d(0, -2px, 0);
                    border: 1px solid #dddddd6e;
                }

                .img-wrap {
                    width: 160px;
                    height: 160px;
                    margin: 0 auto 18px;
                    text-align: center;
                    line-height: 160px;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }

                h3 {
                    font-size: 14px;
                    text-align: center;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    margin: 5px 15px;
                }

                .desc {
                    margin: 0 30px 10px;
                    height: 20px;
                    font-size: 12px;
                    color: #b0b0b0;
                    // text-align: center;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }

                .price {
                    margin: 0 10px 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: red;

                    .num {
                        white-space: nowrap;
                        font-size: 16px;
                    }

                    .num-conter {
                        white-space: nowrap;
                        font-size: 12px;
                        line-height: 28px;
                    }

                    del {
                        white-space: nowrap;
                        margin-left: 0.5em;
                        color: #b0b0b0;
                    }
                }
            }
        }
    }

    .bottom-wrap {
        margin-top: 10px;
        width: $width;
        //height: 118px;
        cursor: pointer;
        overflow: hidden;

        img {
            max-width: 100%;
        }
    }
}
</style>

import { render, staticRenderFns } from "./floor-style-ermei-design-2.vue?vue&type=template&id=dc1e4bca&scoped=true"
import script from "./floor-style-ermei-design-2.vue?vue&type=script&lang=js"
export * from "./floor-style-ermei-design-2.vue?vue&type=script&lang=js"
import style0 from "./floor-style-ermei-design-2.vue?vue&type=style&index=0&id=dc1e4bca&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc1e4bca",
  null
  
)

export default component.exports
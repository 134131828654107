<template>
	<div class="design-1 floor-wsw" :id="data.block_name+c_index">
		<div class="head-wrap" v-if="data.value.title.value.text">
            <div class="left-head-warp">
                <el-image
                    @click="$router.pushToTab(data.value.more.value.link)"
                    :src="$img(data.value.titleImg.value.url)"
                    fit="scale-down"
                ></el-image>
                <h2
                    @click="$router.pushToTab(data.value.title.value.link.url)"
                    :style="{ color: data.value.title.value.color }"
                >{{ data.value.title.value.text }}</h2>
                <span
                    class="warp-title"
                    :style="{ color: data.value.subTitle.value.color }"
                >{{ data.value.subTitle.value.text }}</span>
            </div>
            <div class="right-head-warp">
                <div
                    @click="$router.pushToTab(data.value.more.value.link.url)"
                    :style="{ color: data.value.more.value.color }"
                >
                    <span
                        :style="{ color: data.value.more.value.color }"
                    >{{ data.value.more.value.text }}</span>
                    <i
                        :style="{ color: data.value.more.value.color }"
                        class="icon el-icon-arrow-right"
                    ></i>
                </div>
            </div>
        </div>
		<div class="design-1-body-wrap">
			<div class="wrp-img">
				<div v-for="(item,index) in data.value.topImgs" :key="index">
					<el-image :src="$img(item.value.url) " fit='scale-down'  @click="$router.pushToTab(item.value.link)" style=" height:300px; width:100%" ></el-image>
				</div>
			</div>
			<div class="wrp-swiper">
				<vue-seamless-scroll :data="data.value.bannerImgs" :class-option="optionLeft" class="v-swiper">
					<ul class="swiper-ul" :style="{ width: 120 * data.value.bannerImgs.length + 'px' }">
						<li v-for="(item, index) in data.value.bannerImgs" :key="index">
							<img :src="$img(item.value.url)"  @click="$router.pushToTab(item.value.link)"  style="width: 120px; height: 100px"/>
						</li>
					</ul>
				</vue-seamless-scroll>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "floor-style-ermei-design-2",
	props: {
		data: {
			type: Object
		},
		c_index:{
            type: Number
        }
	},
	data() {
		return {
			// listData: [
			// 	{
			// 		url: "https://res.ryuantong.com/upload/1/common/images/20240301/20240301044152170928251203783_MID.png"
			// 	},
			// 	{
			// 		url: "https://res.ryuantong.com/upload/1/common/images/20240301/20240301044152170928251203783_MID.png"
			// 	},
			// 	{
			// 		url: "https://res.ryuantong.com/upload/1/common/images/20240301/20240301044152170928251203783_MID.png"
			// 	},
			// 	{
			// 		url: "https://res.ryuantong.com/upload/1/common/images/20240301/20240301044152170928251203783_MID.png"
			// 	},
			// 	{
			// 		url: "https://res.ryuantong.com/upload/1/common/images/20240301/20240301044152170928251203783_MID.png"
			// 	},
			// 	{
			// 		url: "https://res.ryuantong.com/upload/1/common/images/20240301/20240301044152170928251203783_MID.png"
			// 	},
			// 	{
			// 		url: "https://res.ryuantong.com/upload/1/common/images/20240301/20240301044152170928251203783_MID.png"
			// 	},
			// 	{
			// 		url: "https://res.ryuantong.com/upload/1/common/images/20240301/20240301044152170928251203783_MID.png"
			// 	},
			// 	{
			// 		url: "https://res.ryuantong.com/upload/1/common/images/20240301/20240301044152170928251203783_MID.png"
			// 	},
			// 	{
			// 		url: "https://res.ryuantong.com/upload/1/common/images/20240301/20240301044152170928251203783_MID.png"
			// 	},
			// 	{
			// 		url: "https://res.ryuantong.com/upload/1/common/images/20240301/20240301044152170928251203783_MID.png"
			// 	},
			// 	{
			// 		url: "https://res.ryuantong.com/upload/1/common/images/20240301/20240301044152170928251203783_MID.png"
			// 	},
			// 	{
			// 		url: "https://res.ryuantong.com/upload/1/common/images/20240301/20240301044152170928251203783_MID.png"
			// 	},
			// 	{
			// 		url: "https://res.ryuantong.com/upload/1/common/images/20240301/20240301044152170928251203783_MID.png"
			// 	},
			// 	{
			// 		url: "https://res.ryuantong.com/upload/1/common/images/20240301/20240301044152170928251203783_MID.png"
			// 	},
			// 	{
			// 		url: "https://res.ryuantong.com/upload/1/common/images/20240301/20240301044152170928251203783_MID.png"
			// 	},
			// 	{
			// 		url: "https://res.ryuantong.com/upload/1/common/images/20240301/20240301044152170928251203783_MID.png"
			// 	},
			// 	{
			// 		url: "https://res.ryuantong.com/upload/1/common/images/20240301/20240301044152170928251203783_MID.png"
			// 	}
			// ]
		}
	},
	computed: {
		optionLeft() {
			return {
				limitMoveNum: 2,
				direction: 3
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.design-1 {
    .head-wrap {
        height: 50px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        border-bottom: 1px solid #f9f9f9;
    }

    .head-wrap {
        .left-head-warp {
            text-align: center;
            display: flex;
            align-items: center;
            // img {
            //     display: inline-block;
            //     vertical-align: middle;
            //     width: 30px;
            //     height: 30px;
            //     margin-right: 10px;
            // }
            .el-image {
                width: 30px;
                height: 30px;
            }
            .warp-title {
                margin-left: 10px;
            }
            h2 {
                margin-left: 10px;
                display: inline-block;
                vertical-align: middle;
                text-align: center;
                font-size: 18px;
                line-height: 30px;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        span {
            color: #b0b0b0;
            font-size: 14px;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .el-icon-arrow-right {
            font-size: 16px;
        }
    }
	&-body-wrap {
		.wrp-img {
			height: 300px;
			display: flex;
			margin-bottom: 10px;
			cursor: pointer;
			&>div {
				height: 300px;
				flex: 1;
				margin-left: 10px;
				border-radius: 15px;
				overflow: hidden;

				.el-image {
					border-radius: 5px;
				}

				&:nth-child(3n + 1) {
					margin-left: 0px;
				}
			}
		}

		.wrp-swiper {
			overflow: hidden;
			.v-swiper {
					width: 135px* 9;
					height: 120px;
					margin: 0 auto;
					overflow: hidden;
				}
			.swiper-ul {
				display: flex;
			
				&>li {
					box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
					cursor: pointer;
				}
			}
		}
	}
}
</style>
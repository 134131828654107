<template>
    <div class="floor-multi-sector" :id="data.block_name+c_index">
        <template v-if="data.block_name == 'floor-style-10'">
            <div class="rowThreeBox">
                <template v-for="item in data.value.list">
                    <div class="rowItem">
                        <div class="title" @click="jumpToLink(item.title.value.link.url)">
                            <div :style="returnTitleBorderStyle(item.title.value)">
                                <span :style="returnTitleStyle(item.title.value)">{{ item.title.value.text }}</span>
                            </div>
                            <div>
                                <span>查看更多</span>
                                <span :style="returnTitleStyle(item.title.value)" class="el-icon-arrow-right"></span>
                            </div>
                        </div>
                        <div class="content">
                            <template v-for="(mainAndNext,mainIndex) in returnArr(item.goodsList.value.list)">
                                <div :class="['default',mainIndex>0?'next':'']">
                                <template v-for="goodsItem in mainAndNext">
                                    <div @click="goSku(goodsItem)" class="goodsItem hoverBox hoverBoder">
                                        <div>
                                            <img :src="$util.img(goodsItem.goods_image)" :alt="goodsItem.goods_name" />
                                            <div class="goodsActive">
                                                <template v-for="imgItem in returnActive(goodsItem)">
                                                    <img :src="$util.img(imgItem.imgUrl_short)" alt="" />
                                                </template>
                                            </div>
                                        </div>
                                        <div>
                                            <p class="goodsName">{{ goodsItem.goods_name }}</p>
                                            <div v-if="(goodsItem.attr&&goodsItem.attr.attr_country_code!='')||goodsItem.is_jc==1||goodsItem.traceability_code==1" class="goodsTip">
                                                <div v-if="goodsItem.attr&&goodsItem.attr.attr_country_code!=''">
                                                    <span>国家码</span>
                                                </div>
                                                <div v-if="goodsItem.is_jc==1">
                                                    <span>追溯码</span>
                                                </div>
                                                <div v-if="goodsItem.traceability_code==1">
                                                    <span>集采</span>
                                                </div>
                                            </div>
                                            <p class="p3">{{ goodsItem.attr&&goodsItem.attr.attr_specs?goodsItem.attr.attr_specs:'暂无规格' }}</p>
                                            <div class="priceBox">
                                                <span>￥{{ returnPrice(goodsItem).main_price }}</span>
                                                <span v-if="returnPrice(goodsItem).next_price>0">￥{{ returnPrice(goodsItem).next_price }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            </template>
                        </div>
                    </div>
                </template>
            </div>
        </template>
        <template v-if="data.block_name == 'floor-style-11'">
            <div class="rowFourBox">
                <template v-for="item in data.value.list">
                    <div class="rowItem">
                        <div class="title" @click="jumpToLink(item.title.value.link.url)">
                            <div :style="returnTitleBorderStyle(item.title.value)">
                                <span :style="returnTitleStyle(item.title.value)">{{ item.title.value.text }}</span>
                            </div>
                            <div>
                                <span>查看更多</span>
                                <span :style="returnTitleStyle(item.title.value)" class="el-icon-arrow-right"></span>
                            </div>
                        </div>
                        <div class="content">
                            <div class="default">
                                <template v-for="goodsItem in item.goodsList.value.list">
                                    <div v-if="goodsItem!=null" @click="goSku(goodsItem)" class="goodsItem hoverBox hoverBoder">
                                        <div>
                                            <img :src="$util.img(goodsItem.goods_image)" :alt="goodsItem.goods_name" />
                                            <div class="goodsActive">
                                                <template v-for="imgItem in returnActive(goodsItem)">
                                                    <img :src="$util.img(imgItem.imgUrl_short)" alt="" />
                                                </template>
                                            </div>
                                        </div>
                                        <div>
                                            <p class="goodsName">{{ goodsItem.goods_name }}</p>
                                            <div v-if="(goodsItem.attr&&goodsItem.attr.attr_country_code!='')||goodsItem.is_jc==1||goodsItem.traceability_code==1" class="goodsTip">
                                                <div v-if="goodsItem.attr&&goodsItem.attr.attr_country_code!=''">
                                                    <span>国家码</span>
                                                </div>
                                                <div v-if="goodsItem.is_jc==1">
                                                    <span>追溯码</span>
                                                </div>
                                                <div v-if="goodsItem.traceability_code==1">
                                                    <span>集采</span>
                                                </div>
                                            </div>
                                            <p class="p3">{{ goodsItem.attr&&goodsItem.attr.attr_specs?goodsItem.attr.attr_specs:'暂无规格' }}</p>
                                            <div class="priceBox">
                                                <span>￥{{ returnPrice(goodsItem).main_price }}</span>
                                                <span v-if="returnPrice(goodsItem).next_price>0">￥{{ returnPrice(goodsItem).next_price }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import { onTip } from "@/utils/tips"
export default {
    props:{
        data: {
            type: Object
        },
        c_index:{
            type: Number
        }
    },
    data() {
        return {
            classState: '',
            arr: [1, 2 , 3 , 4]
        }
    },
    computed: {
        ...mapGetters(["token", "defaultGoodsImage"])
    },
    created() {
    },
    mounted() {
        this.data.value.list.forEach(item => {
            item.goodsList.value.list.forEach(goodsItem => {
                goodsItem.goods_image = goodsItem.goods_image?goodsItem.goods_image:this.defaultGoodsImage;
            })
        })
    },
    methods: {
        returnActive(info){
            let arr = [
				{
					condition: () => !!info.is_getfree,
					content: '换购',
					imgUrl: '/upload/uniapp/goods/l-huangou.png',
					imgUrl_short: '/upload/uniapp/goods/huangou-short.png'
				},
				{
					condition: () => info.promotion_label === 'seckill',
					content: '秒杀',
					imgUrl: '/upload/uniapp/goods/l-miaosha.png',
					imgUrl_short: '/upload/uniapp/goods/miaosha-short.png'
				},
				{
					condition: () => info.promotion_label === 'discount',
					content: '特价',
					imgUrl: '/upload/uniapp/goods/l-tejia.png',
					imgUrl_short: '/upload/uniapp/goods/tejia-short.png'
				},
				{
					condition: () => info.promotion_label === 'wholesale',
					content: '批发',
					imgUrl: '/upload/uniapp/goods/l-pifa.png',
					imgUrl_short: '/upload/uniapp/goods/pifa-short.png'
				},
				{
					condition: () => false,
					content: '控销',
					imgUrl: '/upload/uniapp/goods/l-kongxiao.png',
					imgUrl_short: '/upload/uniapp/goods/l-kongxiao.png'
				}
			];
			let newArr = arr.filter(item => this.shouldRender(item));
			return newArr;
        },
        shouldRender(item) {
			return item.condition();
		},
        goSku(item) {
            if (!this.token) {
                this.$router.pushToTab("/login")
                return
            }
            if (onTip(item.sale_control.view_price,item.sale_control.is_member_by,this,item.sale_control.not_buy_msg)) return
            this.$router.pushToTab("/sku-" + item.sku_id)
        },
        returnPrice(item) {
            let obj = {
                main_price: 0,
                next_price: 0
            }
            if(this.token && item.sale_control && item.sale_control.view_price){
                obj.main_price = item.discount_price;
                obj.next_price = item.market_price;
            }else{
                if(!this.token){
                    obj.main_price = '登录可见';
                }else{
                    obj.main_price = item.sale_control.not_buy_msg || '暂无权限';
                }
            }
            return obj;
        },
        jumpToLink(link){
            this.$router.pushToTab(link);
        },
        returnTitleStyle(info){
            if(info.color!=''){
                return `color:${info.color};`
            }else{
                return ''
            }
        },
        returnTitleBorderStyle(info){
            if(info.color!=''){
                return `border-left-color:${info.color};`
            }else{
                return ''
            }
        },
        returnArr(info){
            let newArr = []
            if(info.length>0){
                let temInfo = info.filter(item =>{
                    if(item!=null&&item!=undefined){
                        return item
                    }
                })
                if(temInfo.length>3){
                    let arr1 = temInfo.slice(0,3);
                    let arr2 = temInfo.slice(3,temInfo.length);
                    newArr.push(arr1);
                    newArr.push(arr2);
                }else{
                    newArr.push(temInfo);
                }
            }
            return newArr;
        }
    }
}
</script>

<style lang="scss" scoped>
$color1: #FF577D;

.floor-multi-sector {
    display: flex;
    flex-direction: column;
}

.rowThreeBox {
    display: flex;
    gap: 26px;

    .rowItem {
        width: calc((100% - 26px * 2) / 3);
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
        border-radius: 9px;
        padding-top: 5px;
        flex-shrink: 0;

        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            

            >div:nth-child(1) {
                border-left: 3px solid $color1;
                padding-left: 10px;
                cursor: default;
                >span {
                    font-weight: 500;
                    font-size: 24px;
                    color: $color1;
                    line-height: 34px;
                }
            }

            >div:nth-child(2) {
                display: flex;
                align-items: center;
                padding-right: 12px;
                cursor: pointer;

                >span:nth-child(1) {
                    font-weight: 400;
                    font-size: 18px;
                    color: #979797;
                    line-height: 25px;
                }

                >span:nth-child(2) {
                    font-weight: bold;
                    color: $color1;
                    font-size: 18px;
                }
            }
        }

        .content {
            padding: 10px;
            box-sizing: border-box;

            .default {
                display: flex;
                gap: 24px;
            }

            .next {
                border-top: 1px dashed #D8D8D8;
                padding-top: 10px;
                margin-top: 10px;
            }
        }

        .goodsItem {
            display: flex;
            width: calc((100% - 30px * 2) / 3);
            flex-direction: column;
            cursor: pointer;

            >div:nth-child(1) {
                display: flex;
                width: 100px;
                height: 100px;
                position: relative;
                border: 1px solid #f5f5f5;
                box-sizing: border-box;
                border-radius: 4px;
                overflow: hidden;
                margin-bottom: 6px;

                >img {
                    width: 100%;
                    height: 100%;
                }

                .goodsActive {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    gap: 2px;
                    >img{
                        height: 100%;
                    }
                }
            }

            >div:nth-child(2) {
                display: flex;
                flex-direction: column;
                gap: 6px;

                .goodsTip {
                    display: flex;
                    align-items: center;
                    gap: 3px;

                    >div {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 35px;
                        height: 13px;
                        background: rgba($color: #FF577D, $alpha: 0.15);
                        border-radius: 5px 5px 5px 5px;

                        >span {
                            font-weight: 400;
                            font-size: 10px;
                            color: #FF577D;
                            line-height: 14px;
                        }
                    }
                }

                >p:nth-child(1) {
                    font-weight: 600;
                    font-size: 15px;
                    color: #3D3D3D;
                    line-height: 21px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                >.p3 {
                    font-weight: 400;
                    font-size: 12px;
                    color: #979797;
                    line-height: 17px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                .priceBox {
                    display: flex;
                    align-items: flex-end;

                    >span:nth-child(1) {
                        font-weight: 500;
                        font-size: 16px;
                        color: #FF0000;
                        line-height: 18px;
                    }

                    >span:nth-child(2) {
                        font-weight: 500;
                        font-size: 12px;
                        color: #979797;
                        line-height: 14px;
                        text-decoration: line-through;
                    }
                }
            }
        }
    }
}

.rowFourBox {
    display: flex;
    gap: 21px;
    .rowItem {
        width: calc((100% - 21px * 3) / 4);
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
        border-radius: 9px;
        padding-top: 5px;
        flex-shrink: 0;

        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;

            >div:nth-child(1) {
                border-left: 3px solid $color1;
                padding-left: 10px;
                cursor: default;

                >span {
                    font-weight: 500;
                    font-size: 24px;
                    color: $color1;
                    line-height: 34px;
                }
            }

            >div:nth-child(2) {
                display: flex;
                align-items: center;
                padding-right: 12px;
                cursor: pointer;

                >span:nth-child(1) {
                    font-weight: 400;
                    font-size: 18px;
                    color: #979797;
                    line-height: 25px;
                }

                >span:nth-child(2) {
                    font-weight: bold;
                    color: $color1;
                    font-size: 18px;
                }
            }
        }

        .content {
            padding: 10px;
            box-sizing: border-box;

            .default {
                display: flex;
                gap: 24px;
            }

            .next {
                border-top: 1px dashed #D8D8D8;
                padding-top: 10px;
                margin-top: 10px;
            }
        }

        .goodsItem {
            display: flex;
            width: calc((100% - 21px) / 2);
            flex-direction: column;
            cursor: pointer;

            >div:nth-child(1) {
                display: flex;
                width: 100px;
                height: 100px;
                position: relative;
                border: 1px solid #f5f5f5;
                box-sizing: border-box;
                border-radius: 4px;
                overflow: hidden;
                margin-bottom: 6px;

                >img {
                    width: 100%;
                    height: 100%;
                }

                .goodsActive {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    gap: 2px;
                    >img{
                        height: 100%;
                    }
                }
            }

            >div:nth-child(2) {
                display: flex;
                flex-direction: column;
                gap: 6px;

                .goodsTip {
                    display: flex;
                    align-items: center;
                    gap: 3px;

                    >div {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 35px;
                        height: 13px;
                        background: rgba($color: #FF577D, $alpha: 0.15);
                        border-radius: 5px 5px 5px 5px;

                        >span {
                            font-weight: 400;
                            font-size: 10px;
                            color: #FF577D;
                            line-height: 14px;
                        }
                    }
                }

                >p:nth-child(1) {
                    font-weight: 600;
                    font-size: 15px;
                    color: #3D3D3D;
                    line-height: 21px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                >.p3 {
                    font-weight: 400;
                    font-size: 12px;
                    color: #979797;
                    line-height: 17px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                .priceBox {
                    display: flex;
                    align-items: flex-end;

                    >span:nth-child(1) {
                        font-weight: 500;
                        font-size: 16px;
                        color: #FF0000;
                        line-height: 18px;
                    }

                    >span:nth-child(2) {
                        font-weight: 500;
                        font-size: 12px;
                        color: #979797;
                        line-height: 14px;
                        text-decoration: line-through;
                    }
                }
            }
        }
    }
}
</style>
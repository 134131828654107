var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"floor-style-6 floor-wsw"},[_vm._m(0),_c('div',{staticClass:"min"},[_c('div',{staticClass:"floor-left"},_vm._l((_vm.leftIfo),function(item,index){return _c('div',{key:index,staticClass:"floor-cut",class:{ active: _vm.actives == index },on:{"click":function($event){return _vm.onshowstlel(index)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',{staticClass:"floor-rigin"},[_c('nav',_vm._l((_vm.data.value.goodsList.value.list),function(item,index){return _c('li',{key:index},[_vm._m(1,true),_vm._m(2,true),_c('div',{staticClass:"cart-layout"},[_c('div',[_c('shareCounter',{attrs:{"number":item.min_buy,"goodsSkuDetail":{
                                min_buy: item.min_buy,
                                stock: item.goods_stock,
                                package_num: item.package_num || item.min_buy,
                                is_member_by: item.sale_control?.is_member_by,
                                view_price: item.sale_control?.view_price,
                                sale_control_name: item.sale_control?.sale_control_name
                            },"size":"mini"},on:{"upnumber":_vm.upnumber}})],1),(_vm.$store.getters.token)?[_c('div',{staticClass:"cart-add",class:item.sale_control && item.sale_control.view_price ? 'cart-add' : 'ban',on:{"click":function($event){$event.stopPropagation();return _vm.joinCart(item)}}},[_vm._v("加入购物车")])]:[_c('div',{staticClass:"cart-add",on:{"click":function($event){$event.stopPropagation();return _vm.joinCart(item)}}},[_vm._v("加入购物车")])]],2)])}),0)])]),_c('div',{staticClass:"floor-oncli"},[_vm._v("点击查看更多")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"floor-heder"},[_c('img',{attrs:{"src":require("../../../assets/images/tehui.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":"https://image.shenglitaiyy.com/upload/1/common/images/20240322/20240322014149171108610918546.png","alt":""}}),_c('div',{staticClass:"tags"},[_vm._v("秒杀")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cont"},[_c('p',{staticClass:"name"},[_vm._v("名称吗fig那法师民工阿飞")]),_c('div',{staticClass:"num"},[_c('span',[_vm._v("起购数量：5")]),_c('span',[_vm._v("限购数量：20")])]),_c('div',{staticClass:"price"},[_c('span',{staticClass:"sklle"},[_vm._v("秒杀")]),_vm._v(" "),_c('span',{staticClass:"discount"},[_vm._v("¥17.8")]),_vm._v(" "),_c('del',{staticClass:"original"},[_vm._v("¥19.5")])]),_c('p',[_vm._v("某某哦米高处公司")]),_c('p',[_vm._v("规格：120g/袋")]),_c('p',[_vm._v("效期：2024-08-12")]),_c('p',[_vm._v("库存：有货")]),_c('p',[_vm._v("中包装：1")])])
}]

export { render, staticRenderFns }
<template>
    <div class="floor-style-6 floor-wsw">
        <div class="floor-heder">
            <img src="../../../assets/images/tehui.png" alt="">
        </div>
        <div class="min">
            <div class="floor-left">
                <div class="floor-cut" :class="{ active: actives == index }" @click="onshowstlel(index)"
                    v-for="(item, index) in leftIfo" :key="index">
                    {{ item.name }}
                </div>
            </div>
            <div class="floor-rigin">
                <nav>
                    <li v-for="(item, index) in data.value.goodsList.value.list" :key="index">
                        <div class="img">
                            <img src="https://image.shenglitaiyy.com/upload/1/common/images/20240322/20240322014149171108610918546.png"
                                alt="">
                            <div class="tags">秒杀</div>
                        </div>
                        <div class="cont">
                            <p class="name">名称吗fig那法师民工阿飞</p>
                            <div class="num"><span>起购数量：5</span><span>限购数量：20</span> </div>
                            <div class="price"><span class="sklle">秒杀</span> <span class="discount">¥17.8</span> <del
                                    class="original">¥19.5</del></div>
                            <p>某某哦米高处公司</p>
                            <p>规格：120g/袋</p>
                            <p>效期：2024-08-12</p>
                            <p>库存：有货</p>
                            <p>中包装：1</p>

                        </div>
                        <div class="cart-layout">
                            <div>
                                <shareCounter :number="item.min_buy" :goodsSkuDetail="{
                                    min_buy: item.min_buy,
                                    stock: item.goods_stock,
                                    package_num: item.package_num || item.min_buy,
                                    is_member_by: item.sale_control?.is_member_by,
                                    view_price: item.sale_control?.view_price,
                                    sale_control_name: item.sale_control?.sale_control_name
                                }" size="mini" @upnumber="upnumber"></shareCounter>
                            </div>
                          <template v-if="$store.getters.token">
                            <div class="cart-add"
                             :class="item.sale_control && item.sale_control.view_price ? 'cart-add' : 'ban'"
                            @click.stop="joinCart(item)">加入购物车</div>
                          </template>
                          <template v-else>
                            <div class="cart-add"
                            @click.stop="joinCart(item)">加入购物车</div>
                          </template>
                        </div>
                    </li>
                </nav>
            </div>
        </div>
        <div class="floor-oncli">点击查看更多</div>
    </div>
</template>

<script>
import { onTip } from "@/utils/tips"
import shareCounter from "@/components/shareCounter.vue"
export default {
    components: {
        shareCounter
    },
    props: {
        data: {
            type: Object
        },
        c_index: {
            type: Number
        }
    },
    data: () => {
        return {
            leftIfo: [
                {
                    name: "秒杀活动",
                    vllue: 1,
                },
                {
                    name: "特价活动",
                    vllue: 2,
                },
                {
                    name: "换购活动",
                    vllue: 3,
                },
                {
                    name: "批发活动",
                    vllue: 4,
                },
            ],
            actives: 0,
            number: 1
        }

    },
    methods: {
        onshowstlel(index) {
            this.actives = index
        },
        upnumber(val) {
            this.number = val
        },
        // 加入购物车
        joinCart(item) {
            if (onTip(item.sale_control?.view_price,item.sale_control?.is_member_by,this,item.sale_control?.not_buy_msg)) return
            this.$store
                .dispatch("cart/add_to_cart", {
                    site_id: item.site_id,
                    sku_id: item.sku_id,
                    num: this.number
                })
                .then(res => {
                    if (res.code === 0) {
                        this.$message({
                            message: "加入购物车成功",
                            type: "success"
                        })
                    }
                })
                .catch(err => {
                    if (err.message === "token不存在") {
                        this.$router.pushToTab("/login")
                    } else {
                        this.$message.error(err.message)
                    }
                })
        },
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-input-number--mini {
    width: 90px !important;
}

.floor-style-6 {
    margin-bottom: 20px;

    .floor-oncli {
        border-radius: 5px;
        font-size: 16px;
        color: #fff;
        width: 370px;
        height: 51px;
        line-height: 51px;
        text-align: center;
        background: linear-gradient(29deg, #e6a23c -89%, #ff547b 58%);
        margin: 0 auto;
    }

    .floor-heder {
        display: flex;
        justify-content: center;
        height: 100px;

        img {
            width: auto;
            height: 70px;
        }
    }

    .min {
        display: flex;
        margin-bottom: 30px;

        .floor-left {
            width: 150px;
            margin-right: 30px;
            min-height: 300px;

            .active {
                width: 154px;
                height: 105px;
                color: #FBDCAA;
                font-weight: bold;
                font-size: 25px !important;
                background: linear-gradient(29deg, #e6a23c -89%, #ff547b 58%) !important;
                &::after {
                    position: absolute;
                    right: -40px;
                    top: 36px;
                    content: '';
                    border-top: 20px solid transparent;
                    border-bottom: 20px solid transparent;
                    border-left: 20px solid #ff547b;
                    border-right: 20px solid transparent;
                }
            }

            .floor-cut {
                width: 154px;
                height: 105px;
                background: #fff;
                line-height: 105px;
                margin-bottom: 10px;
                text-align: center;
                font-size: 18px;
                border-radius: 15px;
                position: relative;

          
            }
        }

        .floor-rigin {
            flex: 1;

            nav {
                display: flex;

                li {
                    box-sizing: border-box;
                    width: 200px;
                    margin-left: 10px;
                    transition: all 0.2s linear;
                    border: 1px solid rgba(221, 221, 221, 0.431372549);
                    border-radius: 8px;
                    overflow: hidden;

                    &:nth-child(6n + 1) {
                        margin-left: 0;
                    }

                    .cont {
                        padding: 10px 10px;
                    }

                    .img {
                        position: relative;

                        img {
                            text-align: center;
                            border-top-left-radius: 8px;
                            border-top-right-radius: 8px;
                            width: 100%;
                            height: 190px;
                        }

                        .tags {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 80px;
                            height: 30px;
                            border-bottom-right-radius: 20px;
                            font-size: 16px;
                            font-weight: bold;
                            color: #fff;
                            text-align: center;
                            background: linear-gradient(58.2deg, #ff547b -9%, rgba(230, 162, 60, 1.34902) 36.3%, #ff547b 97.7%);
                        }
                    }

                    .name {
                        font-size: 16px;
                        font-weight: 600;
                        display: -webkit-box;
                        /*将对象转为弹性盒模型展示*/
                        -webkit-box-orient: vertical;
                        /*设置弹性盒模型子元素的排列方式*/
                        -webkit-line-clamp: 1;
                        /*限制文本行数*/
                        overflow: hidden;
                        /*超出隐藏*/
                    }

                    .num {
                        font-size: 13px;
                        color: #FF7D9B;

                        span {
                            margin-right: 5px;
                        }
                    }

                    .price {
                        .sklle {
                            border-radius: 5px;
                            padding: 2px 5px;
                            color: #EE443F;
                            border: 1px solid #EE443F;
                            margin-right: 2px;
                            background: rgb(238 69 63 / 14%);
                        }

                        .discount {
                            font-size: 25px;
                            color: #EE443F;
                            margin-right: 2px;
                        }

                        .original {
                            font-size: 13px;
                            color: #969696;
                        }
                    }

                    p {
                        color: #787878;
                        font-size: 13px;
                    }

                    &:hover {
                        z-index: 2;
                        -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                        box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                        -webkit-transform: translate3d(0, -2px, 0);
                        transform: translate3d(0, -2px, 0);
                        border: 1px solid #dddddd6e;
                    }
                }
            }
        }
    }

    .cart-layout {
        // padding: 0 0px;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        .el-input-number--mini {
            width: 85px !important;
        }

        .cart-num {
            margin-right: 10px;
            position: relative;
            width: 30%;
            border-radius: 4px;
            display: flex;
            border: 1px solid #ccc;
            justify-content: center;
            align-items: center;

            span {
                z-index: 1;
                position: absolute;
                cursor: pointer;
                width: 20px;
                text-align: center;
            }

            span:first-child {
                left: 0;
            }

            span:last-child {
                right: 0;
            }

            .number {
                .el-input {
                    .el-input__inner {
                        text-align: center;
                    }
                }
            }
        }

        .cart-add {
            font-size: 12px;
            margin-left: 5px;
            cursor: pointer;
            background: $base-color;
            color: $base-color-whtie;
            border-radius: 15px;
            padding: 2px 10px;
        }
        .ban{
            font-size: 12px;
            margin-left: 5px;
            cursor: not-allowed;
            background:#dddddd6e;
            color: $base-color-whtie;
            border-radius: 15px;
            padding: 2px 10px;
       }
    }
}
</style>
import Config from "@/utils/config"
import { adList } from "@/api/website"
import { floors, floatLayer, apiDefaultSearchWords } from "@/api/pc"
import { mapGetters } from "vuex"
import { couponList, receiveCoupon } from "@/api/coupon"
import { goodsPage, timeList } from "@/api/promotion/seckill"
import { exchangePage } from "@/api/exchange"
import CountDown from "vue2-countdown"
import debounce from "lodash/debounce"
import SmoothScroll from "smooth-scroll"
import { onTip } from "@/utils/tips"
import http from '@/utils/http'
let scroll = new SmoothScroll('a[href*="#"]', {
    speed: 500
})
export default {
    name: "index",
    components: {
        CountDown
    },
    data: () => {
        return {
            loadingAd: true,
            loadingFloor: true,
            adList: [],
            adLeftList: [],
            adRightList: [],
            adBttomList:[],
            couponList: [],
            floorList: [],
            adSlideshowList:[],
            floatLayer: {
                is_show: false,
                link: {
                    url: ""
                }
            },
            floor_url:{},//底部广告框活动跳转地址
            floor_img_url:'', //底部广告框图片地址
            indexFloatLayerNum: 0,
            shopApplyUrl: Config.baseUrl + "/shop/login/register",
            shopCenterUrl: Config.baseUrl + "/shop/index/index",
            storeUrl: Config.baseUrl + "/store",
            isSub: false,
            siteId: 0,
            listData: [],
            exchangelistData: [],
            seckillTimeMachine: {
                currentTime: 0,
                startTime: 0,
                endTime: 0
            },
            seckillText: "距离结束",
            backgroundColor: "", // 顶部banner背景颜色
            // 悬浮搜索
            searchType: "goods",
            searchTypeText: "商品",
            keyword: "",
            defaultSearchWords: "",
            isShow: false,
            isShowFloors: false,
            floor_ids: [],
            more_floor: {},
            index_ctrl:{},
            activeSectionIndex: 1,
            number:0,
            activayinopt:'',
            inputs:1,
            closeBotomImg:true
        }
    },
    watch: {
        token(newVal, oldVal) {
            // 退出登录刷新换购和秒杀专区 组件不更新状态
            this.$refs.seckillRef.reset()
            this.$refs.exchangeRef.reset()
        },
        searchType() {
            this.searchTypeText = this.searchType == "goods" ? "商品" : "店铺"
        }
    },
    created() {
        this.getmoreConfig()
        this.getAdList()
        this.getBigAdList()
        this.getSmallAdList()
        this.getFloors()
        this.getFloatLayer()
        this.getBotoomAdList()
        this.getSlideshowAdList()
        this.$store.dispatch("site/siteInfo") // 站点信息
        this.getDefaultSearchWords() // 默认搜索类型
        if (this.addonIsExit && this.addonIsExit.seckill == 1) {
            this.getTimeList()
        }
        this.getCanReceiveCouponQuery()
        this.exchange()
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll)
    },
    destroy() {
        // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
        window.removeEventListener("scroll", this.updateActiveSection)
    },
    computed: {
        ...mapGetters(["token", "defaultHeadImage", "addonIsExit", "defaultGoodsImage", "member", "siteInfo", "cartCount"]),
        optionLeft() {
            return {
                direction: 2,
                limitMoveNum: 1,
                autoPlay: this.listData.length >= 4 ? true :false
            }
        },
        tradeoptionLeft() {
            return {
                direction: 2,
                limitMoveNum: 1,
                autoPlay: this.exchangelistData.length >= 4 ? true :false
            }
        },
    },
    methods: {
        closeImg(){
             this.closeBotomImg = false
        },
        seckillItemAdapter(item) {
            return {
                ...item,
                price: item.price,
                discount_price: item.seckill_price,
            }
        },
        getFreeItemAdapter(item) {
            let temObj = {
                ...item,
                ...item.goods,
                get_free_goods:{
                    ...item,
                    getFreeGoods:item.get_free_goods
                }
            }
            return temObj;
        },
        upnumber(val) {
            this.number = val
        },
        handleClickGoods(event) {
            if (!this.$store.getters.token) {
                this.$router.pushToTab("/login")
                return
            }
           if(JSON.parse(event.target.dataset.num)){
            this.joinCart(JSON.parse(event.target.dataset.num))
           }else {
            let  item= JSON.parse(event.target.dataset.item)
            let   {view_price,not_buy_msg,is_member_by} =item.sale_control
              let view_prices = view_price ? view_price : false
                 let mesg=is_member_by ? is_member_by : '暂无权限'
              // 查找最近的包含 `data-id` 的父元素
              if (this.token) {
                  if (view_prices) {
                      const sku_id = item.sku_id
                      sku_id && this.$router.pushToTab("/sku-" + sku_id)
                  } else {
                      let tomsg = mesg ? mesg : '暂无权限'
                      this.$message.error(tomsg)
                  }

              } else {
                  this.$router.pushToTab('/login')
              }
           }
        },
            // 加入购物车
            joinCart(item) {
                if (onTip(item.sale_control.view_price, item.sale_control.is_member_by,this,item.sale_control.sale_control)) return
                this.$store
                    .dispatch("cart/add_to_cart", {
                        site_id: item.site_id,
                        sku_id: item.sku_id,
                        num: this.number
                    })
                    .then(res => {
                        if (res.code === 0) {
                            this.$message({
                                message: "加入购物车成功",
                                type: "success"
                            })
                        }
                    })
                    .catch(err => {
                        if (err.message === "token不存在") {
                            this.$router.pushToTab("/login")
                        } else {
                            this.$message.error(err.message)
                        }
                    })
            },
            // 换购加入购物车
            seckilljoinCart(e,item){
                e.stopPropagation()
                if (onTip(item.goods.sale_control.view_price, item.goods.sale_control.is_member_by,this,item.goods.sale_control.not_buy_msg)) return
                this.$store
                    .dispatch("cart/add_to_cart", {
                        site_id: item.goods.site_id,
                        sku_id: item.goods.sku_id,
                        num: this.number
                    })
                    .then(res => {
                        if (res.code === 0) {
                            this.$message({
                                message: "加入购物车成功",
                                type: "success"
                            })
                        }
                    })
                    .catch(err => {
                        if (err.message === "token不存在") {
                            this.$router.pushToTab("/login")
                        } else {
                            this.$message.error(err.message)
                        }
                    })
            },
        oMort(){
           if(this.token){
            this.$router.push('/activity#seckill')
           }else{
            this.$router.push('/login')
           }
        },

        async getmoreConfig() {
            let res = await http({ url: "/pc/api/pc/moreConfig" })
            const { code, data } = res
            if (code >= 0) {
                this.more_floor = data.more_floor;
                this.index_ctrl = data.index;
            }
        },
        countDownS_cb() { },
        countDownE_cb() {
            this.seckillText = "活动已结束"
        },
        getAdList() {
            adList({
                keyword: "NS_PC_INDEX"
            })
                .then((res) => {
                    this.adList = res.data.adv_list
                    for (let i = 0; i < this.adList.length; i++) {
                        if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url)
                    }
                    this.backgroundColor = this.adList[0].background
                    this.loadingAd = false
                })
                .catch((err) => {
                    this.loadingAd = false
                })
        },
        handleChange(curr, pre) {
            this.backgroundColor = this.adList[curr].background
        },
        /**
         * 广告位大图
         */
        getBigAdList() {
            adList({
                keyword: "NS_PC_INDEX_MID_LEFT"
            })
                .then((res) => {
                    this.adLeftList = res.data.adv_list
                    for (let i = 0; i < this.adLeftList.length; i++) {
                        if (this.adLeftList[i].adv_url) this.adLeftList[i].adv_url = JSON.parse(this.adLeftList[i].adv_url)
                    }
                    this.loadingAd = false
                })
                .catch((err) => {
                    this.loadingAd = false
                })
        },
        /**
         * 广告位小图
         */
        getSmallAdList() {
            adList({
                keyword: "NS_PC_INDEX_MID_RIGHT"
            })
                .then((res) => {
                    this.adRightList = res.data.adv_list
                    for (let i = 0; i < this.adRightList.length; i++) {
                        if (this.adRightList[i].adv_url) this.adRightList[i].adv_url = JSON.parse(this.adRightList[i].adv_url)
                    }
                    this.loadingAd = false
                })
                .catch((err) => {
                    this.loadingAd = false
                })
        },
        /**
         * 底部广告位
         */
                getBotoomAdList() {
                    adList({
                        keyword: "NS_PC_INDEX_BOTTOM"
                    })
                        .then((res) => {
                            this.adBttomList = res.data.adv_list
                            for (let i = 0; i < this.adBttomList.length; i++) {
                                if (this.adBttomList[i].adv_url) this.adBttomList[i].adv_url = JSON.parse(this.adBttomList[i].adv_url)
                            }
                            this.loadingAd = false
                        })
                        .catch((err) => {
                            this.loadingAd = false
                        })
                },
         /**
         * 首页弹框广告位
         */
             getSlideshowAdList() {
                         adList({
                                keyword: "NS_PC_INDEX_LAYER"
                            })
                                .then((res) => {
                                    this.adSlideshowList = res.data.adv_list
                                    for (let i = 0; i < this.adSlideshowList.length; i++) {
                                        if (this.adSlideshowList[i].adv_url) this.adSlideshowList[i].adv_url = JSON.parse(this.adSlideshowList[i].adv_url)
                                    }
                                    this.loadingAd = false
                                })
                                .catch((err) => {
                                    this.loadingAd = false
                                })
            },
        //获取优惠券列表
        getCanReceiveCouponQuery() {
            couponList({
                activeName: "second",
                site_id: this.siteId
            })
                .then((res) => {
                    let data = res.data
                    this.couponList = []
                    if (data != null) {
                        for (let i = 0; i < data.list.length; i++) {
                            if (i < 5) {
                                this.couponList.push(data.list[i])
                            }
                        }
                        // this.couponList.forEach((v) => {
                        //     v.useState = 0
                        // })
                    }
                })
                .catch((err) => { })
        },
        /**
         * 领取优惠券
         */
        receiveCoupon(item, index) {
            if (this.isSub) return
            this.isSub = true

            var data = {
                site_id: item.site_id,
                activeName: "second",
                coupon_type_id: item.coupon_type_id
            }
            receiveCoupon(data)
                .then((res) => {
                    var data = res.data
                    let msg = res.message
                    if (res.code == 0) {
                        msg = "领取成功"
                        this.$message({
                            message: msg,
                            type: "success"
                        })
                    } else {
                        this.$message({
                            message: msg,
                            type: "warning"
                        })
                    }
                    let list = this.couponList
                    if (res.data.is_exist == 1) {
                        for (let i = 0; i < list.length; i++) {
                            if (list[i].coupon_type_id == item.coupon_type_id) {
                                list[i].useState = 1
                            }
                        }
                    } else {
                        for (let i = 0; i < list.length; i++) {
                            if (list[i].coupon_type_id == item.coupon_type_id) {
                                list[i].useState = 2
                            }
                        }
                    }

                    this.isSub = false
                    this.$forceUpdate()
                })
                .catch((err) => {
                    if (err.message == "您尚未登录，请先进行登录") {
                        this.$router.push("/login")
                    } else {
                        this.$message.error(err.message)
                    }
                    this.isSub = false
                })
        },
        /**
         * 点击优惠券
         */
        couponTap(item, index) {
            if (item.useState == 0) this.receiveCoupon(item, index)
            else this.toGoodsList(item)
        },
        /**
         * 去购买
         */
        toGoodsList(item) {
            if (item.use_scenario != 1) {
                this.$router.push({
                    path: "/list",
                    query: {
                        platformcouponTypeId: item.platformcoupon_type_id
                    }
                })
            } else {
                this.$router.push("/list")
            }
        },
        /**
         * 限时秒杀
         */
        getTimeList() {
            timeList()
                .then((res) => {
                    if (res.code == 0 && res.data) {
                        let time = new Date(res.timestamp * 1000)
                        let currentTimes = time.getHours() * 60 * 60 + time.getMinutes() * 60 + time.getSeconds()

                        res.data.list.forEach((v, k) => {
                            if (v.seckill_start_time <= currentTimes && currentTimes < v.seckill_end_time) {
                                let seckillId = v.id
                                this.getGoodsList(seckillId)

                                let endTime = parseInt(time.getTime() / 1000) + (v.seckill_end_time - currentTimes)
                                this.seckillTimeMachine = {
                                    currentTime: res.timestamp,
                                    startTime: res.timestamp,
                                    endTime: endTime
                                }
                            }
                        })
                    }
                })
                .catch((err) => {
                    // this.$message.error(err.message)
                })
        },
        /**
         * 秒杀商品
         */
        getGoodsList(id) {
            goodsPage({
                page_size: 0,
                seckill_time_id: id,
                site_id: this.siteId
            })
                .then((res) => {
                    if (res.code == 0 && res.data.list) {
                        this.listData = res.data.list
                    }
                })
                .catch((err) => { })
        },
        /**
         * 换购商品
         */
        exchange(id) {
            exchangePage({
                page_size: 0,
                seckill_id: 1,
                site_id: this.siteId
            })
                .then((res) => {
                    this.exchangelistData = res.list || []
                    // this.exchangelistData.forEach((item,index)=>{
                    //    if(item.num == 0){
                    //     this.$set(subitem, "selected", !subitem.selected)
                    //          item.num=1
                    //    }
                    // })
                })
                .catch((err) => {
                })
        },
        exchangegoSku(skuId) {
            this.$router.pushToTab("/sku-" + skuId)
        },
        /**
         * 图片加载失败
         */
        imageError(index) {
            this.listData[index].goods_image = this.defaultGoodsImage
        },
        imageError2(index) {
            this.exchangelistData[index].goods.goods_image = this.defaultGoodsImage;
        },
        /**
         * 图片加载失败
         */
        adLeftImageError(index) {
            this.adLeftList[index].goods_image = this.defaultGoodsImage
        },
        /**
         * 图片加载失败
         */
        adRightImageError(index) {
            this.adRightList[index].goods_image = this.defaultGoodsImage
        },
        getFloors() {
            floors()
                .then((res) => {
                    res.data.forEach(item => {
                        let styleList = ["floor-style-1", "floor-style-2", "floor-style-4", "floor-style-5", "floor-style-6", "floor-style-7", "floor-style-8", "floor-style-9"]
                        // if (styleList.includes(item.block_name)) {
                        //     item.value.goodsList.value.list.forEach(v => {
                        //         v.num = 1
                        //     })
                        // } else {
                        //     item.value.rightGoodsList.value.list.forEach(v => {
                        //         v.num = 1
                        //     })
                        //     item.value.bottomGoodsList.value.list.forEach(v => {
                        //         v.num = 1
                        //     })
                        // }
                    })
                    this.floorList = res.data
                    this.floorList.forEach(ele => {
                        const has = this.floor_ids.findIndex(item => item.id === ele.id)
                        if (has < 0) {
                            let floorObj = {
                                id: ele.block_name,
                                name: ele.title,
                                active: 0
                            }
                            this.floor_ids.push(floorObj)
                        }
                    })
                    this.$nextTick(() => {
                        this.floor_ids_ActiveSection()
                    })
                })
                .catch((err) => {
                })
        },
        updateActiveSection() {
            let floorsDom = document.querySelectorAll(".floor-wsw")
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            floorsDom.forEach((ele, index) => {
                if (scrollTop >= ele.offsetTop - 300) {
                    this.activeSectionIndex = (index+1)
                    console.log("位置",this.activeSectionIndex);
                    
                }
            })
        },
        floor_ids_ActiveSection() {
            // { id: "floor-style-erMei-design-1", name: "测试楼层一", active: 0 },
            //获取每个模块的参数，因为模块在整页渲染完毕后，offsetTop和scrollHeight值已经是确立的，所以在滚动监听之前可以先获取，暂存在menu中。
            this.floor_ids.forEach((item, index) => {
                let dom = document.getElementById(item.id + index)
                if (dom) {
                    item.offsetTop = dom.offsetTop
                    item.scrollHeight = dom.scrollHeight
                }
            })
            // 监听滚动事件
            window.addEventListener("scroll", debounce(this.updateActiveSectionThrottled, 50))
        },
        // 节流函数
        updateActiveSectionThrottled() {
            // 设置一个时间阈值，例如每200毫秒执行一次
            const throttleInterval = 200

            // 获取当前时间戳
            const now = Date.now()

            // 检查是否距离上一次执行已经过去足够的时间
            if (!this.lastScrollTime || now - this.lastScrollTime >= throttleInterval) {
                this.updateActiveSection()
                // 更新上一次执行的时间戳
                this.lastScrollTime = now
            }
        },
        goto(item, index) {
           let  num= index+1
            // if (index === 0) index = ""
            let anchor = document.querySelector("#" + item.id + num)
            let toggle = document.querySelector("." + item.id + num)
            let options = { speed: 500, easing: "easeOutCubic", offset: 200 }
            scroll.animateScroll(anchor, toggle, options)
        },
    // 获取当天结束的时间戳
    getEndOfDayTimestamp() {
        const now = new Date();
        const endOfDay = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() + 1, // 设置为明天的日期
          0, // 设置小时为 0
          0, // 设置分钟为 0
          0, // 设置秒数为 0
          0  // 设置毫秒数为 0
        );
        return endOfDay.getTime() - 1; // 减去 1 毫秒，得到当天结束的时间戳
      },
        getFloatLayer() {
            floatLayer()
                .then((res) => {
                    if (res.code == 0 && res.data) {
                        this.floatLayer = res.data
                        this.floor_img_url = res.data.floor_img_url || '';
                        this.floor_url = res.data.floor_url ?  JSON.parse(res.data.floor_url)  :  {}; 
                        const currentTime = new Date();
                        let startTime = new Date(this.floatLayer.startTime)
                        let endTime = new Date(this.floatLayer.endTime)
                        let midnight = this.dispe(endTime)

                        if (this.floatLayer.is_show == 1 && (currentTime >= startTime && currentTime <= midnight)) {

                            this.floatLayer.link = JSON.parse(this.floatLayer.url)

                            // 弹框形式，首次弹出 1，每次弹出 0
                            if (!this.floatLayer.img_url) return
                            if(this.floatLayer.type == 1){
                               if(this.$store.state.app.indexFloatLayerNum == 0 ){
                                this.floatLayer.is_show_type = true
                                this.$store.commit("app/SET_FLOAT_LAYER", 1)
                                this.$store.commit("app/NOW_DATE", '')
                               }
                            }else if(this.floatLayer.type == 2){
                                this.floatLayer.is_show_type = true
                                this.$store.commit("app/SET_FLOAT_LAYER", 0)
                                this.$store.commit("app/NOW_DATE", '')
                            }
                            else if(this.floatLayer.type == 3){
                            let index_popwindow_count = this.$store.state.app.date == '' ? true :false
                            var date = this.$store.state.app.date<Date.now()
                            if(index_popwindow_count || date){
                                this.$store.commit("app/NOW_DATE", this.getEndOfDayTimestamp())
                                this.$store.commit("app/SET_FLOAT_LAYER", 0)
                                this.floatLayer.is_show_type = true

                            }
                          }
                        } else {
                            this.floatLayer.is_show_type = false
                        }
                    }
                })
                .catch((err) => err)
        },
        // 当获取数据为凌晨十二点时数据处理
        dispe(time) {
            if (time.getHours() === 0 && time.getMinutes() === 0 && time.getSeconds() === 0) {
                time.setHours(23);
                time.setMinutes(59);
                time.setSeconds(59);
                return time
            }else{
                return time
            }
        },
        closeFloat() {
            this.floatLayer.is_show_type = false
            this.$forceUpdate()
            // this.$store.commit("app/SET_FLOAT_LAYER", -1)
        },
        // 监听滚动条
        handleScroll() {
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

            if (scrollTop >= 680) {
                this.isShow = true
            } else {
                this.isShow = false
            }
            if (scrollTop >= 400) {
                this.isShowFloors = true
            } else {
                this.isShowFloors = false
            }
        },
        destroyed() {
            // 离开该页面需要移除这个监听的事件，不然会报错
            window.removeEventListener("scroll", this.handleScroll)
        },
        /**
         * 默认搜索类型（goods/shop）
         */
        getDefaultSearchWords() {
            apiDefaultSearchWords({}).then((res) => {
                if (res.code == 0 && res.data.words) {
                    this.defaultSearchWords = res.data.words
                }
            })
        },
        handleCommand(command) {
            this.searchType = command
        },
        search() {
            if (this.searchType == "goods")
                this.$router.push({
                    path: "/list",
                    query: {
                        keyword: this.keyword
                    }
                })
            else
                this.$router.push({
                    path: "/street",
                    query: {
                        keyword: this.keyword
                    }
                })
        }
    }
}

<template>
    <div class="design-4 floor-wsw floor-style-6" :id="data.block_name+c_index">
        <div class="head-wrap" v-if="data.value.title.value.text">
            <div class="left-head-warp">
                <el-image
                    @click="$router.pushToTab(data.value.more.value.link)"
                    :src="$img(data.value.titleImg.value.url)"
                    fit="scale-down"
                ></el-image>
                <h2
                    @click="$router.pushToTab(data.value.title.value.link.url)"
                    :style="{ color: data.value.title.value.color }"
                >{{ data.value.title.value.text }}</h2>
                <span
                    class="warp-title"
                    :style="{ color: data.value.subTitle.value.color }"
                    @click="$router.pushToTab(data.value.subTitle.value.link)"
                >{{ data.value.subTitle.value.text }}</span>
            </div>
            <div class="right-head-warp">
                <div
                    @click="$router.pushToTab(data.value.more.value.link.url)"
                    :style="{ color: data.value.more.value.color }"
                >
                    <span
                        :style="{ color: data.value.more.value.color }"
                    >{{ data.value.more.value.text }}</span>
                    <i
                        :style="{ color: data.value.more.value.color }"
                        class="icon el-icon-arrow-right"
                    ></i>
                </div>
            </div>
        </div>
        <div class="design-4-body-wrap">
            <ul class="goods-list">
                <li
                    v-for="(item, index) in data.value.goodsList.value.list"
                    :key="index"
                    :title="item.goods_name"
                    class="hoverBox hoverBoder"
                    @click="goSku(item)"
                >
                    <div class="img-wrap">
                        <img
                            alt="商品图片"
                            :src="$img(item.goods_image.split(',')[0], {size: 'mid'})"
                            @error="imageError(index)"
                        />
                    </div>
                    <h3 class="goodsName">{{ item.goods_name }}</h3>
                    <ctrlShowText :info="item" type="custom" margin="6px 15px 0 15px" size="12px"  gap="10px" color="#b0b0b0"
                    :wesArr="['规格','厂家']" :traArr="['规格','厂家']"></ctrlShowText>
                    <template v-if="$store.getters.token">
                        <p class="price" v-if="item.sale_control && item.sale_control.view_price">
                        <span class="num">¥{{ item.discount_price }}元</span>
                        <del v-if=" item.market_price >0">¥{{ item.market_price }}元</del>
                    </p>
                    <p class="price" v-else-if="logined">
                        <span class="num-conter">¥{{item.sale_control.not_buy_msg}}</span>
                        <!-- <del>¥暂无权限！</del> -->
                    </p>
                    </template>
                     <template  v-else>
                        <p class="price">
                        <span class="num-conter">¥登录可见</span>
                        <!-- <del>¥登录可见</del> -->
                    </p>
                     </template>
                    <div class="cart-layout">
                        <div>
                            <shareCounter
                                :number="item.min_buy"
                                :goodsSkuDetail="{
                                        min_buy:item.min_buy,
                                        package_num:item.package_num,
                                        stock:item.goods_stock,
                                        is_member_by:item.sale_control?.is_member_by,
                                        sale_control_name:item.sale_control?.sale_control_name
                                    }"
                                size="mini"
                                @upnumber="upnumber"
                            ></shareCounter>
                        </div>
                   <template v-if="$store.getters.token">
                    <div
                            v-if="item.sale_control?.is_member_by || true"
                            class="cart-add"
                            @click.stop="joinCart(item)"
                            :class="item.sale_control && item.sale_control?.view_price ? 'cart-add' : 'ban'"
                        >加入购物车</div>
                   </template>
                   <template v-else>
                    <div
                            v-if="item.sale_control?.is_member_by || true"
                            class="cart-add"
                            @click.stop="joinCart(item)"
                        >加入购物车</div>
                   </template>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import { onTip } from "@/utils/tips"
import shareCounter from "@/components/shareCounter.vue"
import ctrlShowText from "@/components/ctrl-show-text/ctrl-show-text.vue";
export default {
    name: "floor-style-ermei-design-4",
    components: {
        shareCounter,
        ctrlShowText
    },
    props: {
        data: {
            type: Object
        },
        c_index:{
            type: Number
        }
    },
    data() {
        return {
            number: 1
        }
    },
    computed: {
        ...mapGetters(["member", "token"])
    },
    methods: {
        upnumber(val) {
            this.number = val
        },
        goSku(item) {
            if (!this.$store.getters.token) {
                this.$router.pushToTab("/login")
                return
            }
            if (onTip(item.sale_control.view_price,item.sale_control.is_member_by,this,item.sale_control.not_buy_msg)) return
            this.$router.pushToTab("/sku-" + item.sku_id)
        },
        // 加入购物车
        joinCart(item) {
            if (onTip(item.sale_control?.view_price,item.sale_control?.is_member_by,this,item.sale_control?.not_buy_msg)) return
            this.$store
                .dispatch("cart/add_to_cart", {
                    site_id: item.site_id,
                    sku_id: item.sku_id,
                    num: this.number
                })
                .then(res => {
                    if (res.code === 0) {
                        this.$message({
                            message: "加入购物车成功",
                            type: "success"
                        })
                    }
                })
                .catch(err => {
                    if (err.message === "token不存在") {
                        this.$router.pushToTab("/login")
                    } else {
                        this.$message.error(err.message)
                    }
                })
        }
    },
    computed: {
        ...mapGetters(["defaultGoodsImage", "cartCount", "siteInfo", "member"]),
        logined: function() {
            return this.member !== undefined && this.member !== "" && this.member != {}
        }
    }
}
</script>

<style lang="scss" scoped>
.design-4 {
    background: #fff;
    margin-bottom: 20px;
       box-sizing: border-box;
    .head-wrap {
        height: 50px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        border-bottom: 1px solid #f9f9f9;
    }

    .head-wrap {
        .left-head-warp {
            text-align: center;
            display: flex;
            align-items: center;
            // img {
            //     display: inline-block;
            //     vertical-align: middle;
            //     width: 30px;
            //     height: 30px;
            //     margin-right: 10px;
            // }
            .el-image {
                width: 30px;
                height: 30px;
            }
            .warp-title {
                margin-left: 10px;
            }
            h2 {
                margin-left: 10px;
                display: inline-block;
                vertical-align: middle;
                text-align: center;
                font-size: 18px;
                line-height: 30px;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
           
                white-space: nowrap;
            }
        }

        span {
            color: #b0b0b0;
            font-size: 14px;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .el-icon-arrow-right {
            font-size: 16px;
        }
    }
    &-body-wrap {
        padding: 0 10px;
              box-sizing: border-box;
        .goods-list {
            box-sizing: border-box;
            margin-top: 10px;
            display: flex;
            flex-wrap: wrap;
            .cart-layout {
                padding: 0 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                ::v-deep .el-input-number--mini {
                    width: 85px !important;
                }
                .cart-add {
                    margin-left: 5px;
                    font-size: 12px;
                    cursor: pointer;
                    background:$base-color;
                    color: $base-color-whtie;
                    border-radius: 15px;
                    padding: 2px 10px;
                    text-wrap: nowrap;
                }
                .ban{
                    margin-left: 5px;
                    font-size: 12px;
                    cursor: not-allowed;
                    background:#dddddd6e;
                    color: $base-color-whtie;
                    border-radius: 15px;
                    padding: 2px 10px;
                }
            } 
            li {
                 box-sizing: border-box;
                width: 188px;
                margin-left: 10px;
                margin-bottom: 15px;
                background: #fff;
                cursor: pointer;
                padding: 10px 0;
                transition: all 0.2s linear;
                border: 1px solid #dddddd6e;
                border-radius: 8px;
                &:nth-child(6n + 1) {
                    margin-left: 0;
                }
                &:hover {
                    z-index: 2;
                    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                    -webkit-transform: translate3d(0, -2px, 0);
                    transform: translate3d(0, -2px, 0);
                    border: 1px solid #dddddd6e;
                }
                .img-wrap {
                    width: 150px;
                    height: 150px;
                    margin: 0 auto 18px;
                    text-align: center;
                    line-height: 150px;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                h3 {
                    font-size: 14px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    margin: 0px 15px;
                }
                .desc {
                    margin-left: 15px;
                    height: 20px;
                    font-size: 12px;
                    color: #b0b0b0;
                    text-align: center;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    text-align: left;
                }
                .price {
                    margin: 5px 20px;
                    display: flex;
                     justify-content: space-between;
                    color: red;
                    .num {
                        white-space:nowrap;
                        font-size: 16px;
                    }
                    .num-conter{
                        white-space: nowrap;
    font-size: 14px;
    line-height: 28px;
                    }
                    del {
                        white-space:nowrap;
                        font-size: 12px;
                        line-height: 28px;
                        // margin-left: 0.5em;
                        color: #b0b0b0;
                    }
                }
            }
        }
    }
}
</style>

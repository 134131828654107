<template>
    <div class="design-3 floor-wsw" :id="data.block_name + c_index">
        <div class="head-wrap" v-if="data.value.title.value.text">
            <div class="left-head-warp">
                <el-image @click="$router.pushToTab(data.value.more.value.link)"
                    :src="$img(data.value.titleImg.value.url)" fit="scale-down"></el-image>
                <h2 @click="$router.pushToTab(data.value.title.value.link.url)"
                    :style="{ color: data.value.title.value.color }">{{ data.value.title.value.text }}</h2>
                <span class="warp-title" :style="{ color: data.value.subTitle.value.color }"
                    @click="$router.pushToTab(data.value.subTitle.value.link)">{{ data.value.subTitle.value.text
                    }}</span>
            </div>
            <div class="right-head-warp">
                <div @click="$router.pushToTab(data.value.more.value.link.url)"
                    :style="{ color: data.value.more.value.color }">
                    <span :style="{ color: data.value.more.value.color }">{{ data.value.more.value.text }}</span>
                    <i :style="{ color: data.value.more.value.color }" class="icon el-icon-arrow-right"></i>
                </div>
            </div>
        </div>
        <div class="design-3-body-wrap">
            <div class="wrp-l">
                <el-image :src="$img(data.value.leftImg.value.url)"
                    @click="$router.pushToTab(data.value.leftImg.value.link)" style="width:100%;height:100%"></el-image>
            </div>
            <div class="wrp-c">
                <div class="l-info hoverBox hoverBoder" v-for="(item, index) in data.value.goodsList.value.list" :key="index"
                    @click="goSku(item)">
                    <div class="info-img">
                        <el-image :src="$img(item.goods_image)" style="height: 128px;width:130px" fit="scale-down"
                            @error="imageError(index)"></el-image>
                    </div>
                    <div class="info-r">
                        <h4 class="goodsName">{{ item.goods_name }}</h4>
                        <ctrlShowText :info="item" type="custom" size="12px"  gap="10px" color="#b0b0b0"
                        :wesArr="['规格','厂家']" :traArr="['规格','厂家']"></ctrlShowText>
                        <template v-if="$store.getters.token">
                            <p class="price" v-if="item.sale_control && item.sale_control.view_price">
                                <span class="num">¥{{ item.discount_price }}元</span>
                                <del v-if="item.market_price > 0">¥{{ item.market_price }}元</del>
                            </p>
                            <p class="price" v-else>
                                <span class="num-conter">¥{{ item.sale_control.not_buy_msg }}</span>
                                <!-- <del>¥暂无权限！</del> -->
                            </p>
                        </template>
                        <template v-else>
                            <p class="price">
                                <span class="num-conter">¥登录可见</span>
                                <!-- <del>¥登录可见</del> -->
                            </p>
                        </template>
                        <div class="cart-layout">
                            <div>
                                <shareCounter :number="item.min_buy" :goodsSkuDetail="{
                                    min_buy: item.min_buy,
                                    stock: item.goods_stock,
                                    package_num: item.package_num || item.min_buy,
                                    is_member_by: item.sale_control?.is_member_by,
                                    view_price: item.sale_control?.view_price,
                                    sale_control_name: item.sale_control?.sale_control_name
                                }" size="mini" @upnumber="upnumber"></shareCounter>
                            </div>
                          <template v-if="$store.getters.token">
                            <div class="cart-add" 
                             :class="item.sale_control && item.sale_control.view_price ? 'cart-add' : 'ban'"
                            @click.stop="joinCart(item)">加入购物车</div>
                          </template>
                          <template v-else>
                            <div class="cart-add" 
                            @click.stop="joinCart(item)">加入购物车</div>
                          </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wrp-r">
                <el-image :src="$img(data.value.rightImg.value.url)"
                    @click="$router.pushToTab(data.value.rightImg.value.link)"
                    style="width:100%;height:100%"></el-image>
            </div>
        </div>
    </div>
</template>

<script>
import shareCounter from "@/components/shareCounter.vue"
import { mapGetters } from "vuex"
import { onTip } from "@/utils/tips"
import ctrlShowText from "@/components/ctrl-show-text/ctrl-show-text.vue";
export default {
    name: "floor-style-ermei-design-3",
    components: {
        shareCounter,
        ctrlShowText
    },
    props: {
        data: {
            type: Object
        },
        c_index: {
            type: Number
        }
    },
    data() {
        return {
            number: 0
        }
    },
    computed: {
        ...mapGetters(["defaultGoodsImage", "cartCount", "siteInfo", "member"]),
        logined: function () {
            return this.member !== undefined && this.member !== "" && this.member != {}
        }
    },
    methods: {
        upnumber(val) {
            this.number = val
        },
        goSku(item) {
            if (!this.$store.getters.token) {
                this.$router.pushToTab("/login")
                return
            }
            if (onTip(item.sale_control.view_price, item.sale_control.is_member_by, this, item.sale_control.not_buy_msg)) return
            this.$router.pushToTab("/sku-" + item.sku_id)
        },
        imageError(index) {
            this.data.value.goodsList.value.list[index].goods_image = this.defaultGoodsImage
        },
        // 加入购物车
        joinCart(item) {
            if (onTip(item.sale_control?.view_price, item.sale_control?.is_member_by, this, item.sale_control?.not_buy_msg)) return
            this.$store
                .dispatch("cart/add_to_cart", {
                    site_id: item.site_id,
                    sku_id: item.sku_id,
                    num: this.number
                })
                .then(res => {
                    if (res.code === 0) {
                        this.$message({
                            message: "加入购物车成功",
                            type: "success"
                        })
                    }
                })
                .catch(err => {
                    if (err.message === "token不存在") {
                        this.$router.pushToTab("/login")
                    } else {
                        this.$message.error(err.message)
                    }
                })
        },
    }
}
</script>

<style lang="scss" scoped>
.design-3 {
    background: #fff;
    margin-bottom: 20px;

    .head-wrap {
        height: 50px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        border-bottom: 1px solid #f9f9f9;
    }

    .head-wrap {
        .left-head-warp {
            text-align: center;
            display: flex;
            align-items: center;

            // img {
            //     display: inline-block;
            //     vertical-align: middle;
            //     width: 30px;
            //     height: 30px;
            //     margin-right: 10px;
            // }
            .el-image {
                width: 30px;
                height: 30px;
            }

            .warp-title {
                margin-left: 10px;
            }

            h2 {
                margin-left: 10px;
                display: inline-block;
                vertical-align: middle;
                text-align: center;
                font-size: 18px;
                line-height: 30px;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        span {
            color: #b0b0b0;
            font-size: 14px;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .el-icon-arrow-right {
            font-size: 16px;
        }
    }

    &-body-wrap {
        padding: 10px;
        display: flex;

        .wrp-l {
            cursor: pointer;
            width: 19%;
            // height: 390px;
        }

        .wrp-c {
            cursor: pointer;
            display: grid;
            width: 60%;
            box-sizing: border-box;
            // height: 390px;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(3, 1fr);
            margin: 0px 10px;

            .l-info {
                cursor: pointer;
                border: 1px solid #dddddd6e;
                display: flex;
                box-sizing: border-box;

                .cart-layout {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    ::v-deep .el-input-number--mini {
                        width: 85px !important;
                    }

                    .cart-num {
                        margin-right: 10px;
                        position: relative;
                        width: 30%;
                        border-radius: 4px;
                        display: flex;
                        border: 1px solid #ccc;
                        justify-content: center;
                        align-items: center;

                        span {
                            z-index: 1;
                            position: absolute;
                            cursor: pointer;
                            width: 20px;
                            text-align: center;
                        }

                        span:first-child {
                            left: 0;
                        }

                        span:last-child {
                            right: 0;
                        }

                        .number {
                            .el-input {
                                .el-input__inner {
                                    text-align: center;
                                }
                            }
                        }
                    }

                    .cart-add {
                        font-size: 12px;
                        margin-left: 5px;
                        cursor: pointer;
                        background: $base-color;
                        color: $base-color-whtie;
                        border-radius: 15px;
                        padding: 2px 10px;
                    }
                    .ban{
                        font-size: 12px;
                        margin-left: 5px;
                        cursor: not-allowed;
                        background:#dddddd6e;
                        color: $base-color-whtie;
                        border-radius: 15px;
                        padding: 2px 10px;
                }
                }

                .info-img {
                    margin-right: 10px;
                    padding: 5px 5px;
                    height: 128px;
                    // .el-image{
                    //     width: 100%;
                    //     height: 128px !;
                    //     .img{
                    //         max-width: 100%;
                    //         height: 0 auto;
                    //     }
                    // }
                }

                .info-r {
                    h4 {
                        margin-top: 10px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                    }

                    .price {
                        // margin: 0 0px 14px;
                        color: red;
                        white-space: nowrap;

                        .num-conter {
                            white-space: nowrap;
                            font-size: 14px;
                            line-height: 28px;
                        }

                        del {
                            font-size: 12px;
                            white-space: nowrap;
                            margin-left: 0.5em;
                            color: #b0b0b0;
                        }
                    }

                    .desc {
                        margin: 0 0px 6px;
                        height: 18px;
                        font-size: 12px;
                        color: #b0b0b0;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                }
            }
        }

        .wrp-r {
            cursor: pointer;
            flex: 1;
        }
    }
}
</style>
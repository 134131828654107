var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"floor-style-2 floor-wsw",attrs:{"id":_vm.data.block_name + _vm.c_index}},[(_vm.data.value.title.value.text)?_c('div',{staticClass:"head-wrap"},[_c('h2',{style:({ color: _vm.data.value.title.value.color }),on:{"click":function($event){return _vm.$router.pushToTab(_vm.data.value.title.value.link.url)}}},[_vm._v(_vm._s(_vm.data.value.title.value.text))])]):_vm._e(),_c('div',{staticClass:"body-wrap"},[_c('ul',{staticClass:"goods-list"},_vm._l((_vm.data.value.goodsList.value.list),function(item,index){return _c('li',{key:index,staticClass:"hoverBox hoverBoder",attrs:{"title":item.goods_name},on:{"click":function($event){return _vm.goSku(item)}}},[_c('div',{staticClass:"img-wrap"},[_c('img',{attrs:{"alt":"商品图片","src":_vm.$img(item.goods_image.split(',')[0], { size: 'mid' })},on:{"error":function($event){return _vm.imageError(index)}}})]),_c('h3',{staticClass:"goodsName"},[_vm._v(_vm._s(item.goods_name))]),_c('p',{staticClass:"desc"},[_vm._v(_vm._s(item.introduction))]),_c('ctrlShowText',{attrs:{"info":item,"type":"custom","margin":"0px 30px 10px 30px","size":"12px","gap":"20px","color":"#b0b0b0","wesArr":['规格','厂家'],"traArr":['规格','厂家']}}),(_vm.$store.getters.token)?[(item.sale_control && item.sale_control.view_price)?_c('p',{staticClass:"price"},[_c('span',{staticClass:"num"},[_vm._v("¥"+_vm._s(item.discount_price)+"元")]),(item.market_price > 0)?_c('del',[_vm._v("¥"+_vm._s(item.market_price)+"元")]):_vm._e()]):_c('p',{staticClass:"price"},[_c('span',{staticClass:"num-conter"},[_vm._v("¥"+_vm._s(item.sale_control.not_buy_msg))])])]:[_vm._m(0,true)],(_vm.$store.getters.token && item.sale_control && item.sale_control.view_price || true)?_c('div',{staticClass:"cart-layout"},[_c('div',[_c('shareCounter',{attrs:{"number":item.min_buy,"goodsSkuDetail":{
                            min_buy: item.min_buy,
                            package_num: item.package_num || item.min_buy,
                            stock: item.goods_stock,
                            is_member_by: item.sale_control?.is_member_by,
                            sale_control_name: item.sale_control?.sale_control_name
                        },"size":"mini"},on:{"upnumber":_vm.upnumber}})],1),(_vm.$store.getters.token)?[(item.sale_control?.is_member_by || true)?_c('div',{staticClass:"cart-add",class:item.sale_control && item.sale_control.view_price ? 'cart-add' : 'ban',staticStyle:{"margin-left":"10px"},on:{"click":function($event){$event.stopPropagation();return _vm.joinCart(item)}}},[_vm._v("加入购物车")]):_vm._e()]:[_c('div',{staticClass:"cart-add",on:{"click":function($event){$event.stopPropagation();return _vm.joinCart(item)}}},[_vm._v("加入购物车")])]],2):_vm._e()],2)}),0)]),(_vm.data.value.bottomImg.value.url)?_c('div',{staticClass:"bottom-wrap"},[_c('img',{attrs:{"src":_vm.$img(_vm.data.value.bottomImg.value.url)},on:{"click":function($event){return _vm.$router.pushToTab(_vm.data.value.bottomImg.value.link.url)}}})]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"price"},[_c('span',{staticClass:"num-conter"},[_vm._v("¥登录可见")])])
}]

export { render, staticRenderFns }
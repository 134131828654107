<template>
  <div class="design8 floor-wsw" :id="data.block_name + c_index">
    <div :class="{'img-box':data.value.full_width}"> 
    <template v-if="data.value.show_type == 2">
      <el-carousel :height="data.value.height + 'px'"  :indicator-position="data.value.images.length > 1 ? 'outside' : 'none'">
      <el-carousel-item v-for="(item, index) in data.value.images" :key="index" >
        <el-image  :src="$img(item.value.url)"  fit="cover"  @click="$router.pushToTab(item.value.link.url)"></el-image>
      </el-carousel-item>
    </el-carousel>
    </template>
    <template v-else>
       <div class="design-img-image"   :style="{ gap: data.value.spacing + 'px', height: data.value.height + 'px' }" >
          <el-image  class="imgall"  v-for="(item, index) in data.value.images" :key="index"  :src="$img(item.value.url)"  fit=""  @click="$router.pushToTab(item.value.link.url)"></el-image>
       </div>
    </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "floor-style-ermei-design-8",
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    c_index: {
      type: [Number, String],
    }
  },
  data() {
    return {

    }
  }
}
</script>
<style lang="scss" scoped>
.design8 {
  margin-bottom: 20px;
  .img-box {
    margin-left: calc((1190px - 100vw) / 2);
    /* 计算并应用左侧的 margin */
    margin-right: calc((1190px - 100vw) / 2);
    /* 计算并应用右侧的 margin */
    position: relative;
    width: 100vw;
  }
 .design-img-image{
  display: flex;
  // flex-wrap: wrap;
  .imgall{
    flex: 1;
  }
 }
  .el-image {
    width: 100%;
    height: auto;

    .img {
      max-width: 100%;
      height: auto !important;
    }
  }
//  ::v-deep .el-carousel__container{
//   height: 100% !important;
//  }
  ::v-deep .el-carousel__arrow--right {
    right: 60px;
  }

  ::v-deep .el-carousel__arrow--left {
    left: 60px;
  }
}
</style>
<template>
    <div>
        <!-- 首页固定区 -->
        <!-- :style="{ background: backgroundColor }" -->
        <div class="index-wrap">
            <div class="index">
                <div class="banner">
                    <el-carousel height="400px" arrow="hover" v-loading="loadingAd" @change="handleChange">
                        <el-carousel-item v-for="item in adList" :key="item.adv_id">
                            <el-image fit="cover" :src="$img(item.adv_image)"
                                @click="$router.pushToTab(item.adv_url.url)" />
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
            <div class="box-banner">
                <div class="bannerInfo">
                    <div class="index">
                        <div class="news">
                            <div class="login-wrap">
                                <div class="avtar">
                                    <router-link to="/member/info">
                                        <img v-if="member.headimg" :src="$img(member.headimg)"
                                            @error="member.headimg = defaultHeadImage" />
                                        <img v-else :src="$img(defaultHeadImage)" />
                                    </router-link>
                                </div>
                                <p style="text-align: center">欢迎来{{ siteInfo.site_name }}</p>
                                <div class="btn" v-if="!member">
                                    <router-link to="/login" class="login">登录</router-link>
                                    <router-link to="/register" class="register">注册</router-link>
                                </div>
                                <div class="memeber-name" v-else>{{ member.nickname }}</div>
                            </div>

                            <!-- 商城服务 -->
                            <div class="server-wrap">
                                <div class="item-wrap">
                                    <div class="item">
                                        <router-link to="/member/order_list">
                                            <a target="_blank">
                                                <i class="iconfont icon-gerenzhongxin" />
                                                <span>个人中心</span>
                                            </a>
                                        </router-link>
                                    </div>
                                    <div class="item">
                                        <router-link to="/member/order_list">
                                            <a target="_blank">
                                                <i class="iconfont icon-dingdanxiangqing-dingdanbianhao" />
                                                <span>我的订单</span>
                                            </a>
                                        </router-link>
                                    </div>
                                    <!-- <div class="item">
                                    <a :href="shopApplyUrl" target="_blank">
                                        <i class="iconfont iconshang" />
                                        <span>招商入驻</span>
                                    </a>
                                </div>
                                <div class="item">
                                    <a :href="shopCenterUrl" target="_blank">
                                        <i class="iconfont iconshangjiazhongxin" />
                                        <span>商家中心</span>
                                    </a>
                                </div>
                                <div class="item" v-if="addonIsExit.store == 1">
                                    <a :href="storeUrl" target="_blank">
                                        <i class="iconfont iconshangjiazhongxin-" />
                                        <span>门店管理</span>
                                    </a>
                                </div> -->
                                </div>
                            </div>
                            <div class="xian"></div>
                            <!-- 商城快讯 -->
                            <div class="notice-wrap">
                                <div class="notice">
                                    <div class="notice-name">商城资讯</div>
                                    <router-link to="/cms/notice" class="notice-more">
                                        更多
                                        <i class="iconfont iconarrow-right"></i>
                                    </router-link>
                                </div>
                                <!-- <div v-for="(item, index) in noticeList" :key="item.id" class="item" v-if="index < 1">
                                <router-link :to="'/cms/notice-' + item.id" target="_blank" tag="a">
                                    <div class="notice-title">{{ item.title }}</div>
                                </router-link>
                            </div>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="content-div" v-if="index_ctrl.coupon==='on'&&addonIsExit.coupon == 1 && couponList.length > 0">
                <div class="coupon">
                    <div class="coupon-title">
                        <p class="coupon-font">领券中心</p>
                        <p class="coupon-en">coupon</p>
                        <p class="coupon-more" @click="$router.push('/coupon')">
                            <span>更多</span>
                            <i class="iconfont iconarrow-right"></i>
                        </p>
                    </div>
                    <ul class="coupon-list">
                        <li :style="isOutDate(item)" v-for="(item, index) in couponList" :key="index"
                            v-if="item.count !== item.lead_count">
                            <p class="coupon-price ns-text-color" v-if="item.type == 'reward'"
                                :class="{ disabled: item.useState == 2 }">
                                ￥
                                <span>{{ parseInt(item.money) }}</span>
                            </p>
                            <p class="coupon-price ns-text-color" v-else-if="item.type == 'discount'"
                                :class="{ disabled: item.useState == 2 }">
                                <span>{{ parseFloat(item.discount) }}</span>折
                            </p>
                            <p class="coupon-term" v-if="item.at_least > 0" :class="{ disabled: item.useState == 2 }">
                                满{{ item.at_least }}可用</p>
                            <p class="coupon-term" v-else :class="{ disabled: item.useState == 2 }">
                                满{{ item.at_least
                                }}可用
                            </p>
                            <p class="coupon-receive ns-ve ns-text-color" v-if="item.count === item.lead_count">已抢完</p>
                            <p class="coupon-receitext-color" @click="couponTap(item, index)" v-else>
                                <span v-if="item.useState === 0">立即领取</span>
                                <span v-else-if="item.useState === 3">已过期</span>
                                <span v-else>去使用</span>
                                <i class="iconfont iconarrow-right"></i>
                            </p>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- 广告 -->
            <div class="content-div" v-if="adLeftList.length > 0 || adRightList.length > 0">
                <div class="ad-wrap">
                    <div class="ad-big">
                        <div class="ad-big-img" v-for="(item, index) in adLeftList" :key="index">
                            <el-image :src="$img(item.adv_image)" fit="cover" @error="adLeftImageError(index)"
                                @click="$router.pushToTab(item.adv_url.url)"></el-image>
                        </div>
                    </div>
                    <div class="ad-small">
                        <div class="ad-small-img" v-for="(item, index) in adRightList" :key="index">
                            <el-image :src="$img(item.adv_image)" fit="cover" @error="adRightImageError(index)"
                                @click="$router.pushToTab(item.adv_url.url)"></el-image>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 限时秒杀 -->
            <div class="content-div" v-if="index_ctrl.seckill==='on' && addonIsExit.seckill == 1 && listData.length > 0">
                <div class="seckill-wrap">
                    <div class="seckill-time">
                        <div class="seckill-time-left">
                            <i class="iconfont iconmiaosha1 ns-text-color"></i>
                            <span class="seckill-time-title ns-text-color">限时秒杀</span>
                            <span>{{ seckillText }}</span>
                            <count-down class="count-down" v-on:start_callback="countDownS_cb()"
                                v-on:end_callback="countDownE_cb()" :currentTime="seckillTimeMachine.currentTime"
                                :startTime="seckillTimeMachine.startTime" :endTime="seckillTimeMachine.endTime"
                                :dayTxt="'：'" :hourTxt="'：'" :minutesTxt="'：'" :secondsTxt="''"></count-down>
                        </div>
                        <div class="seckill-time-right" @click="oMort()">
                            <span>更多商品</span>
                            <i class="iconfont iconarrow-right"></i>
                        </div>
                    </div>
                    <div class="seckill-content">
                        <div @click="handleClickGoods($event)">
                            <!--                            <vue-seamless-scroll ref="seckillRef" v-if="listData.length" :data="listData"-->
                            <!--                                :class-option="optionLeft" class="seamless-warp2">-->
                            <div class="seamless-warp2">
                                <ul class="item" :style="{ width: 250 * listData.length + 'px' }">
                                    <li class="hoverBoder" v-for="(item, index) in listData" :key="index"
                                        :data-item="JSON.stringify(item)">
                                        <goods-list-item :goods="seckillItemAdapter(item)" :up-down-layout="true"
                                            :show-limit="true"></goods-list-item>
                                    </li>
                                </ul>
                            </div>
                            <!--                            </vue-seamless-scroll>-->
                        </div>
                    </div>
                </div>
            </div>
            <!-- 换购专区 -->
            <div class="content-div" v-if="index_ctrl.getfree==='on' && this.exchangelistData.length > 0">
                <div class="seckill-wrap">
                    <div class="seckill-time">
                        <div class="seckill-time-left">
                            <i class="iconfont iconmiaosha1 ns-text-color"></i>
                            <span class="seckill-time-title ns-text-color">换购专区</span>
                        </div>
                        <div class="seckill-time-right" @click="$router.push('/activity#getfree')">
                            <span>更多商品</span>
                            <i class="iconfont iconarrow-right"></i>
                        </div>
                    </div>
                    <div class="seckill-content" v-if="this.exchangelistData.length"
                        @click="exchangeHandleClick($event)">
                        <div class="seamless-warp2">
                            <ul class="item" :style="{ width: 250 * exchangelistData.length + 'px' }">
                                <li class="hoverBoder" v-for="(item, index) in exchangelistData" :key="index">
                                    <goods-list-item :goods="getFreeItemAdapter(item)"
                                        :up-down-layout="true"></goods-list-item>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 楼层区 -->
            <div class="content-div">
                <div class="floor">
                    <div v-for="(item, index) in floorList" :key="index" class="floor_item">
                        <floor-style-1 v-if="item.block_name == 'floor-style-1'" :data="item" :c_index="index + 1" />
                        <floor-style-2 v-if="item.block_name == 'floor-style-2'" :data="item" :c_index="index + 1" />
                        <floor-style-3 v-if="item.block_name == 'floor-style-3'" :data="item" :c_index="index + 1" />
                        <!-- <floor-style-6
                             v-if="item.block_name == 'floor-style-6'"
                            :data="item"
                            :c_index="index"
                        /> -->
                        <floorStyleErmeiDesign1 v-if="item.block_name == 'floor-style-5'" :data="item"
                            :c_index="index + 1" />
                        <!-- <div class="img-box floor-style-8 floor-wsw" :id="item.block_name + (index+1)"
                            v-if="item.block_name == 'floor-style-8'" :c_index="index+1">
                            <el-image :src="$img(item.value.bgImg.value.url)" style="width:100%"
                                @click="$roter.pushToTab(item.value.bgImg.value.link)"></el-image>
                        </div> -->
                        <floorStyleErmeiDesign2 v-if="item.block_name == 'floor-style-9'" :data="item" :c_index="index+1" />
                        <floorStyleErmeiDesign4 v-if="item.block_name == 'floor-style-6'" :data="item"
                            :c_index="index + 1" />
                        <floorStyleErmeiDesign3 v-if="item.block_name == 'floor-style-7'" :data="item"
                            :c_index="index + 1" />
                        <floorStyleErmeiDesign8 v-if="item.block_name == 'floor-style-8'" :data="item"
                            :c_index="index + 1" />
                        <floorMultiSector v-if="item.block_name == 'floor-style-10'||item.block_name == 'floor-style-11'" :c_index="index + 1" :data="item"></floorMultiSector>
                    </div>
                </div>
            </div>

            <!-- 浮层区 -->
            <div class="floatLayer-wrap" v-if="floatLayer.is_show_type && adSlideshowList.length>0">
                <div class="floatLayer">
                    <div class="img-wrap" :style="'width:' + adSlideshowList[0]?.width + 'px'">
                            <el-carousel   arrow="never" :height="adSlideshowList[0]?.height + 'px'" style="width: 100%;"
                                indicator-position="none">
                                <el-carousel-item v-for="(item, index) in adSlideshowList" :key="index">
                                    <el-image :src="$img(item.adv_image)" 
                                        @click="$router.pushToTab(item.adv_url.url)"></el-image>
                                </el-carousel-item>
                        </el-carousel>
                    </div>
                    <i class="el-icon-circle-close" @click="closeFloat"></i>
                </div>
            </div>

            <!-- 悬浮搜索 -->
            <div class="fixed-box" :style="{ display: isShow ? 'block' : 'none' }">
                <div class="header-search">
                    <el-row>
                        <el-col :span="6">
                            <router-link to="/" class="logo-wrap">
                                <img :src="$img(siteInfo.logo)" />
                            </router-link>
                        </el-col>
                        <el-col :span="13">
                            <div class="in-sousuo">
                                <div class="sousuo-box">
                                    <!--                                    <el-dropdown @command="handleCommand" trigger="click">-->
                                    <!--                                        <span class="el-dropdown-link">-->
                                    <!--                                            {{ searchTypeText }}-->
                                    <!--                                            <i class="el-icon-arrow-down"></i>-->
                                    <!--                                        </span>-->
                                    <!--                                        <el-dropdown-menu slot="dropdown">-->
                                    <!--                                            <el-dropdown-item command="goods">商品</el-dropdown-item>-->
                                    <!--                                            <el-dropdown-item command="shop">店铺</el-dropdown-item>-->
                                    <!--                                        </el-dropdown-menu>-->
                                    <!--                                    </el-dropdown>-->
                                    <span class="el-dropdown-link" style="padding: 0 10px">
                                        {{ searchTypeText }}
                                    </span>
                                    <input type="text" :placeholder="defaultSearchWords" v-model="keyword"
                                        @keyup.enter="search" maxlength="50" />
                                    <el-button type="primary" size="small" @click="search">搜索</el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="5">
                            <div class="cart-wrap">
                                <router-link class="cart" to="/cart">
                                    <span>我的购物车</span>
                                    <el-badge v-if="cartCount" :value="cartCount" type="primary">
                                        <i class="iconfont icongouwuche"></i>
                                    </el-badge>
                                    <i v-else class="iconfont icongouwuche"></i>
                                </router-link>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
        <div class="floors—going" :style="{ display: isShowFloors ? 'block' : 'none' }">
            <div class="floors-top-img-2">
                <img :src="more_floor?.checked_bg_img" />
                <ul :style="returnStyle(more_floor)">
                    <li :style="returnItemCheckedStyle(more_floor, (index + 1) == activeSectionIndex)"
                        @click="goto(item, index)" v-for="(item, index) in floor_ids" :key="index">
                        <a :style="(index + 1) == activeSectionIndex ? { color: more_floor.checked_color } : { color: more_floor.common_color }"
                            :class="item.id + (index + 1)">{{ item.name }}</a>
                    </li>
                    <li class="back-top" @click="ActivesTop(1)">
                        <a :style="{ color: more_floor.common_color }" data-scroll href="#header-top">返回顶部</a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 底部广告弹框 -->
        <div class="content-foot" v-if="closeBotomImg && adBttomList.length>0">
            <div class="foot-imgs">
                <el-carousel arrow="never" :height="adBttomList[0]?.height + 'px'" style="width: 100%;"
                    indicator-position="none">
                    <el-carousel-item v-for="(item, index) in adBttomList" :key="index">
                        <el-image :src="$img(item.adv_image)" fit="cover"
                            @click="$router.pushToTab(item.adv_url.url)"></el-image>
                    </el-carousel-item>
                </el-carousel>
                <!-- <el-image style="width: 100%;" :src="floor_img_url"></el-image> -->
                <i class="el-icon-circle-close  foot-cose-ion " @click.stop="closeImg"></i>
            </div>
        </div>
    </div>
</template>

<script>
import floorStyle1 from "./components/floor-style-1.vue"
import floorStyle2 from "./components/floor-style-2.vue"
import floorStyle3 from "./components/floor-style-3.vue"
import floorStyle6 from "./components/foor-style-6.vue"
import floorStyleErmeiDesign1 from "./components/floor-style-ermei-design-1.vue"
import floorStyleErmeiDesign2 from "./components/floor-style-ermei-design-2.vue"
import floorStyleErmeiDesign3 from "./components/floor-style-ermei-design-3.vue"
import floorStyleErmeiDesign4 from "./components/floor-style-ermei-design-4.vue"
import floorStyleErmeiDesign8 from "./components/floor-style-ermei-design-8.vue"
import floorMultiSector from "./components/floor-multi-sector.vue"
import index from "./_index/index.js"
import member from "@/store/modules/member"
import shareCounter from "@/components/shareCounter.vue"
import { onTip } from "@/utils/tips"
import GoodsListItem from "@/components/goods-list-item/goods-list-item.vue"
export default {
    name: "index",
    computed: {
        member() {
            return member.state.member
        },
        isOutDate() {
            return item => {
                if (item.useState === 3) {
                    return { backgroundColor: "#ccc !important" }
                }
            }
        }
    },
    // beforeMount() {
    //     this.$nextTick(() => {
    //         setTimeout(() => {
    //             console.log("exchangeRef in mounted:", this.$refs.exchangeRefALL);
    //             this.bindEventsToClonedElements();
    //         }, 3000);
    //     });
    // },
    components: { GoodsListItem, floorMultiSector,floorStyle1, floorStyle2, floorStyle3, floorStyle6, floorStyleErmeiDesign1, floorStyleErmeiDesign2, floorStyleErmeiDesign3, floorStyleErmeiDesign4, floorStyleErmeiDesign8, shareCounter },
    mixins: [index],

    methods: {

        // bindEventsToClonedElements() {
        //     if (this.$refs.exchangeRefALL && this.$refs.exchangeRefALL.$el) {
        //         const clonedElements = this.$refs.exchangeRefALL.$el.querySelectorAll('.item > li');
        //         clonedElements.forEach(element => {
        //             element.addEventListener('click', this.handleElementClick);
        //         });
        //     }
        // },
        // handleElementClick(event) {
        //     const itemData = JSON.parse(event.target.dataset.huanitem);
        //     this.goSku(itemData);

        // },
        goSku(item) {
            if (!this.token) {
                this.$router.pushToTab("/login")
                return
            }
            let sale_control = item?.goods ? item.goods.sale_control : item.sale_control
            let sku_id = item.goods ? item.goods.sku_id : item.sku_id
            if (onTip(sale_control.view_price, sale_control.is_member_by, this, sale_control.not_buy_msg)) return
            this.$router.pushToTab({ path: '/sku-' + sku_id })
        },
        ActivesTop(val) {
            setTimeout(() => {
                this.activeSectionIndex = 1
            }, 1000)
        },
        handleClickGoods(event) {
            let item = JSON.parse(event.target.dataset.item)
            const sku_id = item.sku_id
            if (onTip(item.sale_control.view_price, item.sale_control.is_member_by, this, item.sale_control.not_buy_msg)) return
            sku_id && this.$router.pushToTab("/sku-" + sku_id)
        },
        exchangeHandleClick(event) {
            let item, input, rigth, left;

            try {
                item = event.target.dataset.huanitem ? JSON.parse(event.target.dataset.huanitem) : null;
                input = event.target.dataset.input ? JSON.parse(event.target.dataset.input) : null;
                rigth = event.target.dataset.rigth ? JSON.parse(event.target.dataset.rigth) : null;
                left = event.target.dataset.left ? JSON.parse(event.target.dataset.left) : null;
            } catch (e) {
                console.error("JSON parse error: ", e);
                return;
            }

            if (item) {
                if (onTip(item.goods.sale_control.view_price, item.goods.sale_control.is_member_by, this, item.goods.sale_control.not_buy_msg)) return;
                if (item.goods.sku_id) {
                    this.$router.pushToTab("/sku-" + item.goods.sku_id);
                }
            } else {
                if (input) {
                    this.onclickinput(input);
                } else if (rigth) {
                    this.onclickrigth(rigth);
                } else if (left) {
                    this.onclickleft(left);
                }
            }
        },
        onclickinput(input) {

        },
        onclickrigth(rigth) {
            let min_buy = rigth.goods.min_buy  // 起购价
            let package_num = rigth.goods.min_buy  //中包装          ==倍数
            trigth.num -= package_num;
            if (trigth.num <= min_buy) {
                trigth.num = min_buy
            }
        },
        onclickleft(left) {
            let min_buy = left.goods.min_buy  // 起购价
            let package_num = left.goods.min_buy  //中包装          ==倍数
            left.num += package_num;
            if (left.num >= left.goods.goods_stock) {
                left.num = left.goods.goods_stock
            }
        },
        returnStyle(info) {
            return `background-color:${info.common_bg_color};`
        },
        returnItemCheckedStyle(info, bool) {
            if (bool) {
                return `background-color:${info.checked_bg_color};`
            } else {
                return ''
            }

        }
    }
}
</script>

<style lang="scss" scoped>
@import "./_index/index.scss";
</style>

<style lang="scss">
.banner .el-carousel__arrow--right {
    right: 50px;
}

.goods_shux {
    color: #b0b0b0;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-break: break-all;
}

.sale-hint {
    font-size: 12px;
    color: red;
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.floors—going {
    position: fixed;
    z-index: 100;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    border-radius: 4px;
    padding-top: 10px;

    .floors-top-img-2 {
        position: relative;
        margin-top: -24px;
        // overflow: hidden;
        width: 150px;
        min-height: 260px;
        text-align: center;
    }

    ul {
        position: absolute;
        top: 104px;
        left: 40px;
        background-color: white;
        border-radius: 6px;
        overflow: hidden;
    }

    ul>li {
        box-sizing: border-box;
        height: 31px;
        line-height: 32px;
        padding: 0 10px;
        font-size: 12px;
        cursor: pointer;

        a {
            color: #e83a4f;
        }

        //&:hover {
        //    background-color: $base-color-em;
        //    cursor: pointer;
        //
        //    a {
        //        color: #FFFFFF;
        //    }
        //}
    }

    // .actives {
    //     color: $base-color-em;
    //     a {
    //         background-color: unset !important;
    //         color: #ebab34;
    //     }
    // }

    .back-top {
        a {
            color: red;
            cursor: pointer;
        }

        border-top: 1px solid hsla(0, 0%, 47.1%, 0.3);
    }
}
</style>
.count-down {
span {
display: inline-block;
width: 22px;
height: 22px;
line-height: 22px;
text-align: center;
background: #383838;
color: #ffffff;
border-radius: 2px;
}
}
</style>
